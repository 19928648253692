<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue'
import type { DeleteMeterReadingPayload, Meter, MeterReadings } from '@/vuex/asset_overview/types'
import { formatDate, formatValue } from '@/filters/formatting'
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import CalculateKpisPendingInfo from '@/views/Meter/CalculateKpisPendingInfo.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { docsLink } from '@/utils/helpers/locale'
import KebabMenu from '@/components/KebabMenu.vue'
import { Observation } from '@aedifion.io/aedifion-api'
import ReadingEditor from '@/views/Meter/ReadingEditor.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

// #region INITIALIZATION OF PROPS, EMITS, AND COMPOSABLES
interface Props {
  loading?: boolean
  meter?: Meter
  meterAnalysisAvailable?: boolean
  readOnly?: boolean,
  readings: MeterReadings
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  meterAnalysisAvailable: false,
  readOnly: false,
})

defineEmits<{
  (e: 'close-dialog'): void
}>()

const store = useStore()
const { t } = useI18n()
// #endregion

// #region TABLE CONFIGURATION: HEADERS, EDIT MENU ITEMS, UNIT, AND DOCUMENTATION LINK
const headers = ref([{
  align: 'start',
  sortable: true,
  title: t('date'),
  value: 'time',
}, {
  align: 'end',
  sortable: true,
  title: t('reading'),
  value: 'value',
},
{
  align: 'end',
  sortable: true,
  title: '',
  value: 'actions',
},
])

const kebabMenuItems = ref<ActionMenuItem[]>([{
  icon: 'fa:fas fa-pen',
  id: 'edit',
  label: t('actions.edit'),
}, {
  icon: 'fa:fas fa-trash',
  id: 'delete',
  label: t('actions.delete'),
}])

const availableUnitLabelId = () => {
  switch (props.meter?.alphanumericId) {
    case 'CM': return 'kilowatt-hours'
    case 'ELM': return 'kilowatt-hours'
    case 'GASM': return 'cubic-meters'
    case 'HM': return 'kilowatt-hours'
    case 'WSM': return 'generic'
    default:
      throw Error(`Unknown meter type: '${props.meter?.alphanumericId}'`)
  }
}

const unit = computed<string|null>(() => {
  let unitLabelId: string|undefined = props.readings.unitLabelId
  if (!unitLabelId) {
    unitLabelId = availableUnitLabelId()
  }
  const unitLabel = store.getters['labels/label']('units', unitLabelId)
  return unitLabel ? unitLabel.symbol : null
})

const userMetersDocsLink = ref<string|null>(docsLink('userMeters'))
// #endregion

// #region AUTO-TRIGGERING OF METER ANALYSES
const hasAnalysisBeenTriggered = ref(false)

const hasReadingBeenAdded = ref(false)

const shouldAutomaticallyTriggerMeterAnalyses = computed<boolean>(() => hasReadingBeenAdded.value && !hasAnalysisBeenTriggered.value)

onBeforeUnmount(() => {
  // Automatically trigger meter analyses if a reading has been added and the user has not triggered the analyses yet
  if (shouldAutomaticallyTriggerMeterAnalyses.value) {
    store.dispatch('asset_overview/triggerUserMeterAnalysesForAllAnalogMeters',
      {
        analogMeters: store.state.asset_overview.analogMetersToExecuteAnalysis,
        projectId: store.state.projects.currentProjectId,
      })

    if (props.meterAnalysisAvailable) {
      store.dispatch('asset_overview/triggerUserMeterAnalyses')
    }
  }
})
// #endregion

// #region CRUD OPERATIONS ON METER READINGS
const readingToEdit = ref<Observation|null>(null)

const addReading = () => {
  readingToEdit.value = null
  showReadingEditor.value = true
}

const showDeleteReadingConfirmation = ref(false)
const deleteReading = () => {
  store.dispatch('asset_overview/deleteMeterReading', {
    end: new Date(readingToEdit.value?.time as string),
    readingsId: props.readings.dataPointId,
    start: new Date(readingToEdit.value?.time as string),
  } as DeleteMeterReadingPayload)

  showDeleteReadingConfirmation.value = false
}

const showReadingEditor = ref(false)
const editReading = (reading: Observation) => {
  readingToEdit.value = reading
  showReadingEditor.value = true
}

const onDeleteReadingRequested = (reading: Observation) => {
  readingToEdit.value = reading
  showDeleteReadingConfirmation.value = true
}
// #endregion

// #region TRIGGER_ANALYSES FUNCTIONALITY AND BUTTON
const showCalculateKpisPendingInfo = ref(false)

const isCalculateKPIButtonDisabled = computed<boolean>(() => {
  return props.loading || !hasReadingBeenAdded.value
})

const calculateKpis = () => {
  hasReadingBeenAdded.value = false
  hasAnalysisBeenTriggered.value = true

  store.dispatch('asset_overview/triggerUserMeterAnalysesForAllAnalogMeters',
    {
      analogMeters: store.state.asset_overview.analogMetersToExecuteAnalysis,
      projectId: store.state.projects.currentProjectId,
    })

  if (props.meterAnalysisAvailable) {
    store.dispatch('asset_overview/triggerUserMeterAnalyses')
  }

  showCalculateKpisPendingInfo.value = true
}
// #endregion

/**
 * * This ref is exposed, because it is being used in the ReadingsTable.spec.ts
 */
defineExpose({
  hasReadingBeenAdded,
})
</script>

<template>
  <v-card
    class="mb-4"
  >
    <v-card-title class="text-h6 pa-4 bg-neutral-lighten3 mb-4">
      {{ t('title') }}
      <v-btn
        class="ml-2"
        :href="userMetersDocsLink"
        icon
        color="neutral-lighten3"
        rel="noopener noreferrer"
        target="_blank"
        size="x-small"
      >
        <v-icon
          color="neutral-darken2"
          size="18"
        >
          fa:far fa-info-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div
        class="pb-4"
        v-text="t('two_months_hint')"
      />
      <v-data-table
        data-cy="readings-table"
        :headers="headers"
        hide-default-footer
        :items="readings.readings"
        :items-per-page="-1"
        fixed-footer
        sort-asc-icon="fa:fas fa-sort-up"
        sort-desc-icon="fa:fas fa-sort-down"
        :loading="loading"
        :sort-by="[
          {
            key: 'time',
            order: 'desc'
          }
        ]"
        height="240px"
      >
        <template #item.time="{ item }">
          {{ formatDate(item.time) }}
        </template>
        <template #item.value="{ item }">
          {{ formatValue(item.value, { displayUnit: false, unit }) }}&nbsp;<span
            v-if="unit"
            class="text--secondary"
            v-html="unit"
          />
        </template>
        <template #item.actions="{ item }">
          <KebabMenu
            v-if="!props.readOnly"
            :custom-event-data="item"
            :items="kebabMenuItems"
            @edit-option-click="editReading"
            @delete-option-click="onDeleteReadingRequested"
          />
        </template>
        <template #bottom>
          <v-divider
            v-if="!props.readOnly"
            class="border-opacity-100"
          />
          <v-btn
            v-if="!props.readOnly"
            class="ml-1 my-2 d-flex tw-self-start"
            color="primary-darken2"
            data-cy="add-reading-button"
            variant="text"
            @click="addReading"
          >
            <span class="mr-3 text-cta">{{ t('add_meter_reading') }}</span>
            <v-icon
              size="14"
            >
              fa:far fa-plus-circle
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-spacer />
    <v-divider
      class="mt-3 mb-2 border-opacity-100"
    />
    <v-card-actions class="py-4">
      <v-spacer />
      <v-btn
        color="primary-lighten3"
        variant="elevated"
        data-testid="cancel-button"
        @click="$emit('close-dialog')"
      >
        <span class="text-primary-darken2">{{ t('actions.cancel') }}</span>
      </v-btn>
      <v-btn
        color="primary-darken2"
        variant="elevated"
        :disabled="isCalculateKPIButtonDisabled"
        data-testid="calculate-kpis-button"
        @click="calculateKpis"
      >
        {{ t('calculate_kpis') }}
      </v-btn>
    </v-card-actions>
    <ReadingEditor
      v-if="showReadingEditor"
      :meter="meter"
      :reading="readingToEdit"
      :unit="unit"
      :value="showReadingEditor"
      @reading-editor:close="showReadingEditor=false"
      @reading-added="hasReadingBeenAdded = true"
    />
    <ConfirmationDialog
      v-if="showDeleteReadingConfirmation"
      v-model="showDeleteReadingConfirmation"
      accept-button-text-key="actions.delete"
      :title="t('delete_reading_confirmation_title')"
      @confirmation-dialog:accept="deleteReading"
      @confirmation-dialog:cancel="showDeleteReadingConfirmation = false"
    >
      {{ t('delete_reading_confirmation_text') }}
    </ConfirmationDialog>
    <CalculateKpisPendingInfo
      v-if="showCalculateKpisPendingInfo"
      :value="showCalculateKpisPendingInfo"
      @calculate-kpis-info:close="showCalculateKpisPendingInfo = false"
    />
  </v-card>
</template>

<style lang="sass" scoped>
:deep(.v-data-table__wrapper)
  max-height: 475px
  overflow-y: auto
</style>

<i18n locale="de">
  {
    "add_meter_reading": "Zählerstand hinzufügen",
    "calculate_kpis": "KPIs berechnen",
    "date": "Datum",
    "delete_reading_confirmation_text": "Soll der Zählerstand wirklich gelöscht werden?",
    "delete_reading_confirmation_title": "Zählerstand löschen",
    "reading": "Zählerstand",
    "title": "Zählerstände",
    "two_months_hint": "Um Zähler-KPIs zu berechnen werden Zählerstände von mindestens zwei Monaten benötigt."
  }
  </i18n>
  <i18n locale="en">
  {
    "add_meter_reading": "Add meter reading",
    "calculate_kpis": "Calculate KPIs",
    "date": "Date",
    "delete_reading_confirmation_text": "Should the reading really be deleted?",
    "delete_reading_confirmation_title": "Delete reading",
    "reading": "Meter reading",
    "title": "Meter Readings",
    "two_months_hint": "To calculate KPIs, for each meter you should have readings for at least two months."
  }
  </i18n>
