import { BULLET_GRAPH_COLORS, VUETIFY_COLORS as DEFAULT_VUETIFY_COLORS, TIMESERIES_COLORS } from '../default/colors.js'
import { VuetifyColors } from '../types.js'

const VUETIFY_COLORS = {
  ...DEFAULT_VUETIFY_COLORS,
  anchor: '#007982',
  primary: {
    base: '#0098A1',
    darken2: '#007982',
    darken4: '#12585C',
    lighten2: '#83CBD3',
    lighten3: '#DDF4F5',
    lighten4: '#DDF4F5'
  }
} as VuetifyColors

const COCKPIT_NUDGE_GRADIENT = [
  '#007982',
  '#007982',
  '#007982'
] as string[]

export { BULLET_GRAPH_COLORS, COCKPIT_NUDGE_GRADIENT, TIMESERIES_COLORS, VUETIFY_COLORS }
