import { aedifionApi, AnalysisFunction, AnalysisResult, AnalysisResultSummaries, AssignedPin, Success as BaseSuccess, Component, ComponentInProject, ComponentInProjectWithContext, InstanceConfig, Observation, Pin, Result, Tag, TimeseriesWithContext, User } from '@aedifion.io/aedifion-api'
import { AssetOverviewState, DeleteMeterReadingPayload, Meter, MeterAndObservation, MeterIdAndObservation, MeterIdAndTimeseries } from './types'
import { energyPriceAttributeId, readingsPinAlphanumericId, unitLabelId } from './helpers'
import moment, { Moment } from 'moment'
import { showErrorNotification, showInfoNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import state, { resetStoreState } from './state'
import { ActionTree } from 'vuex'
import { captureMessage } from '@sentry/vue'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { RootState } from '../types'
import { TimeseriesValue } from '../data_points_view/types'
import { unPaginate } from '@/vuex/helpers'
import { useMeterStore } from '@/stores/views/Meter'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'

enum Edge {
  Beginning,
  End
}

/**
 * Extracted functions from createUserMeter
 */
async function fetchMeterComponentId (alphanumericId: string): Promise<number> {
  const components: Component[] = await aedifionApi.Component.getComponents()
  const component: Component = components.find((component: Component) => component.alphanumeric_id === alphanumericId)!
  return component.id!
}

async function updateMeterAttributes (meter: Meter, oldMeter: Meter|null, projectId: number, meterComponentId: number): Promise<void> {
  if (oldMeter !== null) {
    const updateAttributeRequests: Array<Promise<BaseSuccess>> = []
    if (meter.alphanumericId !== 'WSM' && meter.co2Emissions !== undefined && oldMeter.co2Emissions !== meter.co2Emissions && oldMeter.co2Emissions !== undefined) {
      updateAttributeRequests.push(aedifionApi.Project.putProjectComponentAttribute(projectId, meterComponentId, `${meter.alphanumericId}+CO2_EMIS_F`, String(meter.co2Emissions)))
    } else if (meter.alphanumericId !== 'WSM' && meter.co2Emissions !== undefined && oldMeter.co2Emissions === undefined) {
      updateAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+CO2_EMIS_F`, value: String(meter.co2Emissions) }, projectId, meterComponentId))
    }
    if (meter.alphanumericId !== 'WSM' && meter.energyPrice !== undefined && oldMeter.energyPrice !== meter.energyPrice && oldMeter.energyPrice !== undefined) {
      updateAttributeRequests.push(aedifionApi.Project.putProjectComponentAttribute(projectId, meterComponentId, energyPriceAttributeId(meter.alphanumericId!)!, String(meter.energyPrice)))
    } else if (meter.alphanumericId !== 'WSM' && meter.energyPrice !== undefined && oldMeter.energyPrice === undefined) {
      updateAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: energyPriceAttributeId(meter.alphanumericId!)!, value: String(meter.energyPrice) }, projectId, meterComponentId))
    }
    if (meter.number !== undefined && oldMeter.number !== meter.number && oldMeter.number !== undefined) {
      updateAttributeRequests.push(aedifionApi.Project.putProjectComponentAttribute(projectId, meterComponentId, `${meter.alphanumericId}+M_NUM`, String(meter.number)))
    } else if (meter.number !== undefined && oldMeter.number === undefined) {
      updateAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+M_NUM`, value: String(meter.number) }, projectId, meterComponentId))
    }
    await Promise.all(updateAttributeRequests)
  } else {
    const postAttributeRequests: Array<Promise<BaseSuccess>> = []
    postAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+DATA_SRC`, value: 'user' }, projectId, meterComponentId))
    if (meter.alphanumericId !== 'WSM') {
      postAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+CALC_COST`, value: 'True' }, projectId, meterComponentId))
    }
    if (meter.alphanumericId !== 'WSM' && meter.co2Emissions !== undefined) {
      postAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+CO2_EMIS_F`, value: String(meter.co2Emissions) }, projectId, meterComponentId))
    }
    if (meter.alphanumericId !== 'WSM' && meter.energyPrice !== undefined) {
      postAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: energyPriceAttributeId(meter.alphanumericId!)!, value: String(meter.energyPrice) }, projectId, meterComponentId))
    }
    if (meter.number !== undefined) {
      postAttributeRequests.push(aedifionApi.Project.postProjectComponentAttribute({ key: `${meter.alphanumericId}+M_NUM`, value: String(meter.number) }, projectId, meterComponentId))
    }
    await Promise.all(postAttributeRequests)
  }
}

function readingsDataPointId (meter: Meter): string {
  const meterNameForDataPoint = meter.meterName?.replace(/\s/g, '-') ?? ''
  return `${meter.alphanumericId}_${meter.number}_${meterNameForDataPoint}`
}

async function createReadingsDataPoint (projectId: number, readingsDataPointId: string): Promise<void> {
  const datapointRequestPayload: { [key: string]: Array<Observation> } = {}
  datapointRequestPayload[readingsDataPointId] = []
  await aedifionApi.Project.postProjectTimeseries(datapointRequestPayload, projectId)
}

async function pinReadingsDataPoint (projectId: number, readingsDataPointId: string, componentId: number, meterComponentId: number, meterComponentAlphanumericId: string): Promise<AssignedPin[]> {
  const readingsPinId: Readonly<number> = await fetchReadingsPinId(componentId, meterComponentAlphanumericId)
  const response: Result<ComponentInProjectWithContext> = await aedifionApi.Project.postProjectComponentPinDatapoint(
    projectId,
    meterComponentId,
    readingsPinId,
    readingsDataPointId) as Result<ComponentInProjectWithContext>
  return response.resource.pins
}

async function fetchReadingsPinId (componentId: number, componentAlphanumericId: string): Promise<number> {
  const pins: Readonly<Pin[]> = await aedifionApi.Component.getComponentPins(componentId)
  const pinAlphanumericId: Readonly<string> = readingsPinAlphanumericId(componentAlphanumericId!)
  const pin = pins.find((pin: Pin) => pin.alphanumeric_id === pinAlphanumericId)
  if (!pin) {
    captureMessage(`Pin '${pinAlphanumericId}' not available, worker may be outdated`, 'error')
    throw new Error()
  }
  return pin.id!
}

async function createReadingsDataPointUnitTag (projectId: number, readingsDataPointId: string, componentAlphanumericId: string): Promise<void> {
  const tagPayload = {
    key: 'units',
    value: unitLabelId(componentAlphanumericId),
  }
  const projectTagResponse: Result<Tag> = (await aedifionApi.Project.postProjectTag(tagPayload, projectId)) as Result<Tag>
  const tagId = projectTagResponse.resource.id
  await aedifionApi.Datapoint.postDatapointTag(readingsDataPointId, projectId, tagId)
}

/**
 * Extracted functions from fetchReadingsOfAllUserMeters
 */
function createHashIdAndMeterIdMap (meters: ComponentInProjectWithContext[]): Map<string, number> {
  const result: Map<string, number> = new Map()
  for (const meterComponent of meters) {
    const requiredPinAlphanumericId: Readonly<string> = readingsPinAlphanumericId(meterComponent.component!.alphanumeric_id!)
    const pin = meterComponent.pins?.find((pin: AssignedPin) => pin.alphanumeric_id === requiredPinAlphanumericId)
    if (pin) {
      result.set(pin.datapoint_hash_id!, meterComponent.id!)
    }
  }
  return result
}

/**
 * Extracted functions from triggerUserMeterAnalyses
 */
async function recreateUserMeterAnalysisInstances (projectId: number, buildingComponent: number, componentId?: string, analysisInstancesForProject?: InstanceConfig[]): Promise<Array<InstanceConfig>> {
  let requiredAnalysisFunctions: AnalysisFunction[]

  if (componentId) {
    requiredAnalysisFunctions = await fetchRequiredUserMeterAnalysisFunctions(componentId)
  } else {
    requiredAnalysisFunctions = await fetchRequiredUserMeterAnalysisFunctions()
  }

  await deleteExistingUserMeterAnalysisInstances(projectId, requiredAnalysisFunctions, analysisInstancesForProject)
  const result = await createUserMeterAnalysisInstances(projectId, buildingComponent, requiredAnalysisFunctions)
  return result
}

export const AnalysisFunctionIds = {
  CO2_EMISSION_ANALYSIS: 'co2_emission_analysis',
  ENERGY_CONSUMPTION_ANALYSIS: 'energy_consumption_analysis',
  ENERGY_COST_ANALYSIS: 'energy_cost_analysis',
  VIRTUAL_HEAT_METER_ANALYSIS: 'virtual_heat_meter_analysis',
} as const

async function fetchRequiredUserMeterAnalysisFunctions (componentId?: string): Promise<AnalysisFunction[]> {
  let requiredAnalysisFunctions: AnalysisFunction[] = []
  const analysisFunctions: AnalysisFunction[] = await aedifionApi.Analytics.getAnalysisFunctions(componentId ?? 'B')

  if (componentId !== undefined) {
    requiredAnalysisFunctions = analysisFunctions.filter(analysisFunction => analysisFunction.alphanumeric_id === AnalysisFunctionIds.VIRTUAL_HEAT_METER_ANALYSIS)
  } else {
    requiredAnalysisFunctions = analysisFunctions.filter(analysisFunction => {
      return Object.values(AnalysisFunctionIds).includes(analysisFunction.alphanumeric_id as typeof AnalysisFunctionIds[keyof typeof AnalysisFunctionIds])
    })
  }

  return requiredAnalysisFunctions
}

async function deleteExistingUserMeterAnalysisInstances (
  projectId: number,
  analysisFunctions: AnalysisFunction[],
  analysisInstancesForProject?: InstanceConfig[],
  analogOnly: boolean = false,
  userMeters: Meter[] = [],
  metersToRemoveInstancesFor?: Meter[],
): Promise<void> {
  let allAnalysisInstances: InstanceConfig[] = []

  if (analysisInstancesForProject) {
    allAnalysisInstances = analysisInstancesForProject
  } else {
    allAnalysisInstances = await aedifionApi.Analytics.getAnalysisInstances(projectId)
  }

  if (analogOnly) {
    allAnalysisInstances = allAnalysisInstances.filter((analysisInstance: InstanceConfig) => {
      return userMeters.some((userMeter: Meter) => {
        return userMeter.id === analysisInstance.config.componentinproject_id && userMeter.type === 'Analog'
      })
    })
  }

  if (metersToRemoveInstancesFor) {
    allAnalysisInstances = allAnalysisInstances.filter((analysisInstance: InstanceConfig) => {
      return metersToRemoveInstancesFor.some((meter: Meter) => {
        return meter.id === analysisInstance.config.componentinproject_id
      })
    })
  }

  const deleteAnalysisInstanceRequests: Array<Promise<BaseSuccess>> = []
  for (const analysisInstance of allAnalysisInstances) {
    if (analysisFunctions.some(analysisFunction => analysisFunction.id === analysisInstance.config.analysisfunction_id)) {
      deleteAnalysisInstanceRequests.push(aedifionApi.Analytics.deleteAnalysisInstance(projectId, analysisInstance.id!))
    }
  }
  await Promise.all(deleteAnalysisInstanceRequests)
}

async function createUserMeterAnalysisInstances (projectId: number, componentInProjectId: number, analysisFunctions: AnalysisFunction[]): Promise<Array<InstanceConfig>> {
  const postAnalysisInstanceRequests: Array<Promise<Result<InstanceConfig>>> = []
  for (const analysisFunction of analysisFunctions) {
    const instanceConfig = {
      analysisfunction_id: analysisFunction.id,
      componentinproject_id: componentInProjectId,
      description: 'frontend-generated',
      enabled: true,
      name: `${analysisFunction.nameEN ?? analysisFunction.nameDE} for Building`,
    }
    // postAnalysisInstance actually returns a Success object
    postAnalysisInstanceRequests.push(aedifionApi.Analytics.postAnalysisInstance(instanceConfig, projectId) as Promise<Result<InstanceConfig>>)
  }
  const result: Array<InstanceConfig> = (await Promise.all(postAnalysisInstanceRequests)).map(item => {
    return item.resource
  })
  return result
}

function userMeterDataPointIds (userMeters: ComponentInProjectWithContext[] | Meter): string[] {
  const result: string[] = []

  if (Array.isArray(userMeters)) {
    for (const meter of userMeters) {
      const meterType = meter.component!.alphanumeric_id!
      const pin = meter.pins?.find(pin => pin.alphanumeric_id === readingsPinAlphanumericId(meterType))
      if (pin) {
        result.push(pin.dataPointID!)
      }
    }
  } else {
    const meterType = userMeters.alphanumericId!
    const pin = userMeters.pins?.find(pin => pin.alphanumeric_id === readingsPinAlphanumericId(meterType))
    if (pin) {
      result.push(pin.dataPointID!)
    }
  }
  return result
}

async function findMostRecentLastTimeseriesDate (projectId: number, dataPointIds: string[]): Promise<Moment> {
  const lastTimeseriesValues: { [key: string]: TimeseriesValue<string> } = await fetchTimeseriesEdge(Edge.End, projectId, dataPointIds)
  return findMostRecentTimeseriesDate(lastTimeseriesValues)
}

async function fetchTimeseriesEdge (edge: Edge, projectId: number, dataPointIds: string[]): Promise<{ [key: string]: TimeseriesValue<string> }> {
  return await aedifionApi.Project.getProjectTimeseries(
    projectId,
    dataPointIds,
    edge === Edge.Beginning ? new Date(2000, 0, 1) : undefined,
    edge === Edge.Beginning ? undefined : new Date(Date.now()),
    1,
    'auto',
    'none',
    undefined,
    true,
    false,
  ) as unknown as { [key: string]: TimeseriesValue<string> }
}

function findMostRecentTimeseriesDate (timeseries: { [key: string]: TimeseriesValue<string> }): Moment {
  let result: Moment|null = null
  for (const meter in timeseries) {
    const current = moment.utc(timeseries[meter][0][0])
    if (!result || current.isAfter(result)) {
      result = current
    }
  }
  return result!
}

async function determineAnalysisStart (projectId: number, analysisInstance: InstanceConfig, meterDataPointIds: string[], firstTimeseriesValues: { [key: string]: TimeseriesValue<string> }): Promise<Moment> {
  let result: Moment
  const previousResultSummaries: AnalysisResultSummaries[] = await aedifionApi.Analytics.getInstanceResults(projectId, analysisInstance.id!)
  if (previousResultSummaries.length === 0) {
    result = await findMostRecentFirstTimeseriesDate(projectId, meterDataPointIds, firstTimeseriesValues)
  } else {
    result = await findMostRecentAnalysisEndDate(projectId, analysisInstance.id!, previousResultSummaries)
  }
  return result
}

async function findMostRecentFirstTimeseriesDate (projectId: number, dataPointIds: string[], timeseries: { [key: string]: TimeseriesValue<string> }): Promise<Moment> {
  if (Object.keys(timeseries).length === 0) {
    Object.assign(timeseries, await fetchTimeseriesEdge(Edge.Beginning, projectId, dataPointIds))
  }
  return findMostRecentTimeseriesDate(timeseries)
}

async function findMostRecentAnalysisEndDate (projectId: number, analysisInstanceId: number, resultSummaries: AnalysisResultSummaries[]): Promise<Moment> {
  const resultRequests: Array<Promise<AnalysisResult>> = []
  for (const resultSummary of resultSummaries) {
    resultRequests.push(aedifionApi.Analytics.getInstanceResult(projectId, analysisInstanceId, resultSummary.result_id!))
  }
  const results: AnalysisResult[] = await Promise.all(resultRequests)
  let result: Moment|null = null
  for (const previousResult of results) {
    if (!result || moment.utc(previousResult.end!) > result) {
      result = moment.utc(previousResult.end!)
    }
  }
  return result!
}

function createAnalysisExecutionRequests (projectId: number, analysisInstanceId: number, firstStart: Moment, end: Moment): Array<() => Promise<BaseSuccess>> {
  let currentStart = firstStart.clone().startOf('day')
  let lastEnd: Moment
  // The lastEnd should always be the beginning of the end month, or the start of the next month if it is on the last day of the month
  if (end.clone().endOf('month').isSame(end, 'day') && end.clone().add(1, 'month').isSameOrBefore(moment(), 'month')) {
    lastEnd = end.clone().add(1, 'month').startOf('month').startOf('day')
  } else {
    lastEnd = end.clone().startOf('month').startOf('day')
  }

  const result: Array<() => Promise<BaseSuccess>> = []

  while (currentStart.isBefore(lastEnd, 'day')) {
    let currentEnd: Moment

    if (currentStart.isSame(lastEnd, 'month')) {
      currentEnd = lastEnd.clone()
    } else {
      currentEnd = currentStart.clone().add(1, 'month').startOf('month')
    }
    result.push(aedifionApi.Analytics.postAnalysisInstanceRun.bind(aedifionApi.Analytics, projectId, analysisInstanceId, currentStart.toDate(), currentEnd.toDate()))
    currentStart = moment(currentEnd)
  }
  return result
}

async function triggerUserMeterAnalysesForAnalogMeter (analogMeter: Meter & {
  analysisFunctions?: AnalysisFunction[]
}): Promise<void> {
  try {
    const projectId: number = validateNotNullish(analogMeter.projectId,
      { errorMessage: i18n.global.t('notifications.errors.no_project_selected') as string },
    )
    const requiredAnalysisFunctions = analogMeter.analysisFunctions ?? await fetchRequiredUserMeterAnalysisFunctions(analogMeter.alphanumericId)
    const analysisInstances: InstanceConfig[] = await createUserMeterAnalysisInstances(projectId, analogMeter.id!, requiredAnalysisFunctions)
    const meterDataPointIds: string[] = userMeterDataPointIds(analogMeter)
    const analysisEnd: Readonly<Moment> = await findMostRecentLastTimeseriesDate(projectId, meterDataPointIds)
    const firstTimeseriesValues: { [key: string]: TimeseriesValue<string> } = {}

    const analysisExecutionRequests: Array<() => Promise<BaseSuccess>> = []
    for (const analysisInstance of analysisInstances) {
      const analysisStart: Moment = await determineAnalysisStart(projectId, analysisInstance, meterDataPointIds, firstTimeseriesValues)
      if (analysisStart.isAfter(moment.utc(new Date()))) {
        continue
      }
      analysisExecutionRequests.push(...createAnalysisExecutionRequests(projectId, analysisInstance.id!, analysisStart, analysisEnd))
    }

    await Promise.all(analysisExecutionRequests.map(request => request()))
  } catch (error) {
    reportError(error)
    const errorMessage = `${i18n.global.t('notifications.errors.functions.toggle_instance', { action: i18n.global.t('actions.triggered') })}`
    showErrorNotification(errorMessage)
  }
}

export default {
  clear: ({ state }): void => {
    resetStoreState(state)
  },

  createOrUpdateUserMeterReading: async ({ commit, rootGetters }, payload: MeterAndObservation): Promise<void> => {
    commit('SET_PENDING_READING_UPDATE', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
      const apiPayload: { [key: string]: Array<Observation> } = {}
      apiPayload[payload.meter.readingsDataPointId!] = [payload.observation]
      await aedifionApi.Project.postProjectTimeseries(apiPayload, projectId)
      commit('ADD_OR_UPDATE_METER_READING', { meterId: payload.meter.id!, observation: payload.observation } as MeterIdAndObservation)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.create', { resource: i18n.global.t('notifications.resources.datapoint') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_READING_UPDATE', false)
    }
  },

  createUserMeter: async ({ commit, rootGetters }, meter: Meter): Promise<void> => {
    commit('SET_PENDING_METER_UPDATE', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
      const componentId: number = await fetchMeterComponentId(meter.alphanumericId!)

      const meterComponentResult: Result<ComponentInProject> = await aedifionApi.Project.postProjectComponent(
        projectId,
        componentId,
        { nameEN: meter.meterName },
      ) as Result<ComponentInProject>
      const meterComponent: Readonly<ComponentInProject> = meterComponentResult.resource

      await updateMeterAttributes(meter, null, projectId, meterComponent.id!)
      const meterComponentWithContext: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId, meterComponent.id!)
      const dataPointId: Readonly<string> = readingsDataPointId(meter)
      await createReadingsDataPoint(projectId, dataPointId)

      await createReadingsDataPointUnitTag(projectId, dataPointId, meter.alphanumericId!)
      const pins: AssignedPin[] = await pinReadingsDataPoint(projectId, dataPointId, componentId, meterComponentWithContext.id!, meter.alphanumericId!)

      const meterWithKpiAggregation = {
        component_in_project: meterComponentWithContext,
        kpi_aggregations: [],
      }

      meterComponentWithContext.pins = pins
      commit('ADD_USER_METER', meterComponentWithContext)

      const meterStore = useMeterStore()

      meterStore.componentsKpiAggregation?.items.push(meterWithKpiAggregation)

      const initialTimeseries: Readonly<TimeseriesWithContext> = {
        data: [],
        dataPointID: dataPointId,
        tags: [],
        units: unitLabelId(meter.alphanumericId!),
      }
      commit('SET_METER_TIMESERIES', { meterId: meterComponentWithContext.id!, timeseries: initialTimeseries } as MeterIdAndTimeseries)

      const successMessage = `${i18n.global.t('notifications.success.create', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showSuccessNotification(successMessage)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.create', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_METER_UPDATE', false)
    }
  },

  deleteMeterReading: async ({ commit, rootGetters }, payload: DeleteMeterReadingPayload): Promise<void> => {
    commit('SET_PENDING_READING_UPDATE', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
      const selectedMeterId = validateNotNullish(state.selectedUserMeter?.id)

      await aedifionApi.Datapoint.deleteDatapointTimeseries(projectId, payload.readingsId, payload.start, payload.end)

      commit('DELETE_METER_READING', {
        meterId: selectedMeterId,
        observation: {
          time: payload.start,
        },
      })

      showSuccessNotification(i18n.global.t('notifications.success.delete', { resource: i18n.global.t('notifications.resources.datapoint') }) as string)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.delete', { resource: i18n.global.t('notifications.resources.datapoint') })}`
      reportError(error)
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_READING_UPDATE', false)
    }
  },

  deleteUserMeter: async ({ commit, rootGetters }, meter: Meter): Promise<void> => {
    commit('SET_PENDING_METER_UPDATE', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)

      const requests: Array<Promise<BaseSuccess>> = []
      requests.push(aedifionApi.Project.deleteProjectComponent(projectId, meter.id!))
      requests.push(aedifionApi.Datapoint.deleteDatapoint(meter.readingsDataPointId!, projectId))
      await Promise.all(requests)

      const meterStore = useMeterStore()
      const index = meterStore.componentsKpiAggregation?.items.findIndex((item) => item.component_in_project.id === meter.id)

      if (index !== undefined && index !== -1) {
        meterStore.componentsKpiAggregation?.items.splice(index, 1)
      }
      commit('REMOVE_USER_METER', meter.id!)
      commit('REMOVE_USER_METER_READINGS', meter.id!)

      const successMessage = `${i18n.global.t('notifications.success.delete', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showSuccessNotification(successMessage)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.delete', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_METER_UPDATE', false)
    }
  },

  editUserMeter: async ({ commit, rootGetters }, { meter, originalMeter }: {meter: Meter, originalMeter: Meter}): Promise<void> => {
    commit('SET_PENDING_METER_UPDATE', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
      if (meter.meterName !== originalMeter.meterName) {
        await aedifionApi.Project.putProjectComponent(
          projectId,
          meter.id!,
          { nameEN: meter.meterName },
        )
      }

      await updateMeterAttributes(meter, originalMeter, projectId, meter.id!)

      const meterComponentWithContext: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId, meter.id!)

      commit('UPDATE_USER_METER', meterComponentWithContext)

      const meterStore = useMeterStore()
      const index = meterStore.componentsKpiAggregation?.items.findIndex((item) => item.component_in_project.id === meter.id)

      if (index !== undefined && index !== -1) {
        meterStore.componentsKpiAggregation!.items[index].component_in_project = meterComponentWithContext
      }

      const successMessage = `${i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showSuccessNotification(successMessage)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_METER_UPDATE', false)
    }
  },

  fetchMeterReadings: async ({ commit, rootGetters, state }, meter: Meter): Promise<void> => {
    if (state.meterTimeseries[meter.id!] !== undefined) return

    commit('SET_LOADING_METER_READINGS', true)
    try {
      const userStore = useUserStore()
      const user: Readonly<User> = validateNotNullish(userStore.userDetails)
      const projectId: Readonly<number> = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)

      const timeseries: TimeseriesWithContext[] = await aedifionApi.Project.getProjectTimeseries(
        projectId,
        [meter.readingsDataPointId!],
        undefined,
        new Date(Date.now()),
        0,
        'auto',
        'none',
        undefined,
        false,
        false,
        user.units_system,
        user.currency_system,
      )

      commit('SET_METER_TIMESERIES', { meterId: meter.id!, timeseries: timeseries[0] } as MeterIdAndTimeseries)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_LOADING_METER_READINGS', false)
    }
  },

  fetchReadingsOfAllUserMeters: async ({ commit, rootGetters }): Promise<void> => {
    commit('SET_LOADING_METER_READINGS', true)
    try {
      const meterIdLookup = createHashIdAndMeterIdMap(state.userMeters || [])
      if (meterIdLookup.size > 0) {
        const userStore = useUserStore()
        const user: Readonly<User> = validateNotNullish(userStore.userDetails)
        const projectId: Readonly<number> = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
        const hashIds: string[] = Array.from(meterIdLookup.keys())
        const allTimeseries: TimeseriesWithContext[] = await aedifionApi.Project.getProjectTimeseries(
          projectId,
          hashIds,
          undefined,
          new Date(Date.now()),
          0,
          'auto',
          'none',
          undefined,
          false,
          false,
          user.units_system,
          user.currency_system,
        )
        for (const timeseries of allTimeseries) {
          commit('SET_METER_TIMESERIES', { meterId: meterIdLookup.get(timeseries.datapoint_hash_id!), timeseries } as MeterIdAndTimeseries)
        }
      }
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_LOADING_METER_READINGS', false)
    }
  },

  fetchUserMeters: async ({ commit, rootGetters }): Promise<void> => {
    commit('SET_LOADING_USER_METERS', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)

      const paramsMap = new Map<
      'project_Id' | 'page' | 'per_page' | 'search' | 'filter',
      string | number | undefined
      >([
        ['project_Id', projectId],
        ['page', 1],
        ['per_page', 100],
        ['search', undefined],
        ['filter', 'alphanumeric_id=CM;alphanumeric_id=ELM;alphanumeric_id=GASM;alphanumeric_id=HM;alphanumeric_id=WSM'],
      ])

        type RequestParams = Parameters<typeof aedifionApi.Project.getProjectComponents>

        const request = async (params: RequestParams) => aedifionApi.Project.getProjectComponents(...params)

        const components = await unPaginate(request, paramsMap)

        const detailsRequests: Array<Promise<ComponentInProjectWithContext>> = []
        for (const component of components.items ?? []) {
          detailsRequests.push(aedifionApi.Project.getProjectComponent(projectId, component.id!))
        }

        const detailsResults: ComponentInProjectWithContext[] = await Promise.all(detailsRequests)
        const meters: ComponentInProjectWithContext[] = []
        for (const meter of detailsResults) {
          meters.push(meter)
        }
        commit('SET_USER_METERS', meters)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_LOADING_USER_METERS', false)
    }
  },

  // The userMeters are only passed in the meterStore.clearStore() function, because the stores are cleared by then and we need the original meters
  triggerUserMeterAnalyses: async ({ commit, state, rootGetters }, userMeters?: Meter[]): Promise<void> => {
    commit('RESET_PENDING_ANALYSIS_EXECUTIONS_COUNT')
    commit('SET_PENDING_ANALYSIS_EXECUTIONS', true)
    try {
      const projectId: number = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)

      const buildingComponent = validateNotNullish(
        rootGetters['building_analyses/buildingComponentOfProject'](projectId) as ComponentInProjectWithContext|null,
        { errorMessage: i18n.global.t('notifications.errors.no_component_in_project') as string },
      )
      const analysisInstances: Array<InstanceConfig> = await recreateUserMeterAnalysisInstances(projectId, buildingComponent.id!)
      const meterDataPointIds: string[] = userMeterDataPointIds(userMeters ?? state.userMeters ?? [])
      const analysisEnd: Readonly<Moment> = await findMostRecentLastTimeseriesDate(projectId, meterDataPointIds)
      const firstTimeseriesValues: { [key: string]: TimeseriesValue<string> } = {}
      const analysisExecutionRequests: Array<() => Promise<BaseSuccess>> = []
      for (const analysisInstance of analysisInstances) {
        const analysisStart: Moment = await determineAnalysisStart(projectId, analysisInstance, meterDataPointIds, firstTimeseriesValues)
        if (analysisStart.isAfter(moment.utc(new Date()))) {
          continue
        }
        analysisExecutionRequests.push(...createAnalysisExecutionRequests(projectId, analysisInstance.id!, analysisStart, analysisEnd))
      }

      commit('SET_TOTAL_PENDING_ANALYSIS_EXECUTIONS', analysisExecutionRequests.length)
      for (const request of analysisExecutionRequests) {
        await request()
        commit('INCREMENT_FINISHED_ANALYSIS_EXECUTIONS')
      }

      const infoMessage = `${i18n.global.t('notifications.info.analysis_running')}`
      showInfoNotification(infoMessage, 10000)
    } catch (error) {
      const errorMessage = `${i18n.global.t('notifications.errors.functions.toggle_instance', { action: i18n.global.t('actions.triggered') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_ANALYSIS_EXECUTIONS', false)
    }
  },

  triggerUserMeterAnalysesForAllAnalogMeters: async ({ state, commit, getters }, { analogMeters, projectId }: {analogMeters: Set<Meter>, projectId: number}): Promise<void> => {
    try {
      validateNotNullish(projectId)

      commit('RESET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS_COUNT')
      commit('SET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS', true)

      const analogMetersAlphanumericIds: string[] = [...new Set([...analogMeters].map(meter => meter.alphanumericId!))]

      const analogMetersAnalysisFunctionsRequests = analogMetersAlphanumericIds.map(async (id) => fetchRequiredUserMeterAnalysisFunctions(id))

      const analogMetersAnalysisFunctions = await Promise.all(analogMetersAnalysisFunctionsRequests)

      const metersToRemoveInstancesFor: Meter[] = [...state.analogMetersToExecuteAnalysis]

      await deleteExistingUserMeterAnalysisInstances(projectId, analogMetersAnalysisFunctions.flat(), undefined, true, getters.userMeters, metersToRemoveInstancesFor)

      const analogMetersWithAnalysisFunctions = [...analogMeters].map(meter => {
        const analysisFunctions = analogMetersAnalysisFunctions.find(analysisFunctions => analysisFunctions.some(analysisFunction => analysisFunction.alphanumeric_id === AnalysisFunctionIds.VIRTUAL_HEAT_METER_ANALYSIS))
        return { ...meter, analysisFunctions }
      })

      const analogMeterAnalysisExecutionRequests = []

      commit('SET_TOTAL_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS', analogMetersWithAnalysisFunctions.length)
      for (const analogMeter of analogMetersWithAnalysisFunctions) {
        analogMeterAnalysisExecutionRequests.push(() => triggerUserMeterAnalysesForAnalogMeter(analogMeter))
      }

      await Promise.all(analogMeterAnalysisExecutionRequests.map(request => {
        commit('INCREMENT_FINISHED_ANALOG_METER_ANALYSIS_EXECUTIONS')
        return request()
      }))

      commit('SET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS', false)

      if (state.pendingAnalysisExecutions === false) {
        const infoMessage = `${i18n.global.t('notifications.info.analysis_running')}`
        showInfoNotification(infoMessage, 10000)
      }
    } catch (error) {
      reportError(error)
      const errorMessage = `${i18n.global.t('notifications.errors.functions.toggle_instance', { action: i18n.global.t('actions.triggered') })}`
      showErrorNotification(errorMessage)
    } finally {
      commit('SET_PENDING_ANALOG_METER_ANALYSIS_EXECUTIONS', false)
      commit('CLEAR_ANALOG_METER_ANALYSIS_EXECUTIONS')
    }
  },
} as ActionTree<AssetOverviewState, RootState>
