import { convertTimeseriesToPlottableData, fetchProjectTimeseries, getTypeOfTimeseriesValue } from '@/utils/helpers/timeseries'
import {
  DatapointIDAndTimeseries,
  DatapointOverviewItem,
  DataPointsViewState,
  DateRangeObject,
  Samplerate,
  TagItem,
} from './types'
import { DataPointWithContext, Project, TagAssociation, TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { getDatapoint, setDatapoint } from '@/services/database/datapoints'
import { ActionTree } from 'vuex'
import { datapointIsWritable } from '@/utils/helpers/tags'
import { DatapointWithContextAndScore } from '@/vuex/datapoints/types'
import { getLeastUsedSeriesColor } from '@/utils/helpers/visualization'
import i18n from '@/i18n'
import { isBinaryBacnetName } from '@/utils/helpers/datapoints'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { TIMESERIES_COLORS } from '@theme/colors'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'

function hasTagWithValue (tags: TagAssociation[], key: string, possibleValues: string[]): boolean {
  for (const tag of tags.filter((tag) => tag.key === key)) {
    if (tag.value && possibleValues.includes(tag.value)) {
      return true
    }
  }
  return false
}

/**
 * Returns a boolean representing whether this timeseries should be treated as
 * binary (as opposed to analog). A timeseries should be treated as binary if
 * any of those conditions is verified:
 * - its first value is of type boolean
 * - it has an `objtype` tag with a value of `binaryValue`, `binaryInput` or
 * `binaryOutput`
 * - it has no `objtype` tag, and it has a `domain` tag with a value of `binary`
 * - its datapoint id has a suffix with `BV`, `BI`, or `BO` and a number
 *
 * A binary timeseries is always fetched without a samplerate and is visualized
 * differently.
 * @param timeseries
 */
function isTimeseriesBinary (timeseries: TimeseriesWithContext, timeseriesType: string): boolean {
  if (timeseriesType === 'boolean') {
    return true
  }
  if (hasTagWithValue(timeseries.tags ?? [], 'objtype', ['binaryValue', 'binaryInput', 'binaryOutput'])) {
    return true
  }
  // at the moment there is a bug in the classification pipeline that
  // mislabels datapoints as binary
  /*
  if (
    timeseries.tags &&
    !timeseries.tags.some((tag) => tag.key === 'objtype') &&
    hasTagWithValue(timeseries.tags, 'domain', ['binary'])
  ) {
    return true
  }
  */
  if (timeseries.dataPointID && isBinaryBacnetName(timeseries.dataPointID)) {
    return true
  }
  return false
}

export default {
  addTagFilter: ({ commit, state }, tagFilter: TagItem) => {
    const duplicateIndex: number = state.tagFilters.findIndex((currentItem: TagItem) => {
      return currentItem.key === tagFilter.key && currentItem.value === tagFilter.value
    })
    if (duplicateIndex < 0) {
      commit('ADD_TAG_FILTER', tagFilter)
    }
  },

  clear: ({ state }) => {
    resetStoreState(state)
  },

  clearFilters: ({ state }) => {
    state.favoritesFilterSet = false
    state.tagFilters = []
    state.writableFilterSet = false
  },

  deselectAllDatapoints: ({ commit, state }) => {
    state.datapointTimeseries = []
    state.datapointsOverview = []
    state.seriesColorMap = new Map()
    commit('REMOVE_ALL_SELECTED_HASH_IDS')
  },

  deselectDatapoint: ({ commit, state }, hashID: string) => {
    const indexOfRemovingHash: number = state.selectedDatapointsHashIds.findIndex((hash: string) => hash === hashID)
    if (indexOfRemovingHash >= 0) {
      state.seriesColorMap.delete(hashID)
      commit('REMOVE_HASH_ID', hashID)
      commit('REMOVE_TIMESERIES', hashID)
      commit('REMOVE_DATAPOINT_FROM_OVERVIEW', hashID)
    }
  },

  fetchTimeseries: async ({ commit, dispatch, getters, rootGetters, rootState, state }, hashIDs: string[]) => {
    validateNotNullish(
      rootState.datapoints.datapoints,
      { errorMessage: 'data_points_view.fetchTimeseries called before datapoints were loaded' },
    )
    commit('SET_LOADING_DATAPOINT_TIMESERIES', true)

    try {
      const userStore = useUserStore()
      const user = validateNotNullish(userStore.userDetails)
      const projectId = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
      const project = validateNotNullish(rootGetters['projects/currentProject'] as Project|null)
      const { start, end }: DateRangeObject = getters.getEffectiveRange
      const samplerate: Samplerate = state.samplerate || 'auto'

      const [analogHashIds, binaryHashIds]: [string[], string[]] = await dispatch('separateByTimeseriesType', hashIDs)

      let analogTimeseriesPromise: Promise<TimeseriesWithContext[]> | undefined
      if (analogHashIds.length > 0) {
        analogTimeseriesPromise = fetchProjectTimeseries(
          projectId,
          analogHashIds,
          user.units_system,
          user.currency_system,
          start,
          end,
          samplerate,
          true,
        )
      }

      let binaryTimeseriesPromise: Promise<TimeseriesWithContext[]> | undefined
      if (binaryHashIds.length > 0) {
        binaryTimeseriesPromise = fetchProjectTimeseries(
          projectId,
          binaryHashIds,
          undefined,
          undefined,
          start,
          end,
          undefined,
          true,
        )
      }

      const analogTimeseries = await analogTimeseriesPromise
      const binaryTimeseries = await binaryTimeseriesPromise

      if (analogTimeseries) {
        await Promise.all(analogTimeseries.map(async (timeseries: TimeseriesWithContext) => {
          const datapointResource = await getDatapoint(project.handle!, timeseries.datapoint_hash_id!)
          const commitPayload: DatapointIDAndTimeseries = {
            dataPointID: timeseries.dataPointID!,
            hashID: timeseries.datapoint_hash_id!,
            isBinary: false,
            timeseries: convertTimeseriesToPlottableData(timeseries.data!),
            timeseriesType: datapointResource?.timeseriesType,
            unitLabelId: timeseries.units,
            visible: true,
          }
          commit('ADD_DATAPOINT_TIMESERIES', commitPayload)
        }))
      }

      if (binaryTimeseries) {
        await Promise.all(binaryTimeseries.map(async (timeseries: TimeseriesWithContext) => {
          const datapointResource = await getDatapoint(project.handle!, timeseries.datapoint_hash_id!)
          const commitPayload: DatapointIDAndTimeseries = {
            dataPointID: timeseries.dataPointID!,
            hashID: timeseries.datapoint_hash_id!,
            isBinary: true,
            timeseries: convertTimeseriesToPlottableData(timeseries.data!),
            timeseriesType: datapointResource?.timeseriesType,
            unitLabelId: timeseries.units,
            visible: true,
          }
          commit('ADD_DATAPOINT_TIMESERIES', commitPayload)
        }))
      }
    } catch (error) {
      showErrorNotification(i18n.global.t('notifications.errors.data_points_view.fetchTimeseries.error') as string)
      reportError(error)
    } finally {
      commit('SET_LOADING_DATAPOINT_TIMESERIES', false)
    }
  },

  resetLiveViewRange: ({ commit }) => {
    commit('SET_LIVE_VIEW_RANGE', null)
  },

  selectDatapoints: async ({ commit, rootGetters, dispatch, state }, hashIds: string[]) => {
    for (const hashId of hashIds) {
      const duplicateIndex: number = state.selectedDatapointsHashIds.findIndex((alreadySelectedHashId: string) => alreadySelectedHashId === hashId)
      if (duplicateIndex < 0) {
        let datapoint: DatapointWithContextAndScore|null = rootGetters['datapoints/datapointWithHashId'](hashId)
        if (datapoint === null) {
          const datapointResponse: DataPointWithContext|undefined = await dispatch('datapoints/fetchDatapoint', hashId, { root: true })
          datapoint = validateNotNullish(datapointResponse)
        }
        const datapointOverviewItem: DatapointOverviewItem = {
          dataPointID: datapoint.dataPointID!,
          hashID: hashId,
          max: null,
          mean: null,
          min: null,
          tags: datapoint.tags,
          title: datapoint.dataPointID!,
          visible: true,
          writable: datapointIsWritable(datapoint),
        }
        const nextAvailableColor: string = getLeastUsedSeriesColor(state.seriesColorMap, TIMESERIES_COLORS)
        state.seriesColorMap.set(hashId, nextAvailableColor as string)
        commit('ADD_HASH_ID', hashId)
        commit('ADD_DATAPOINT_TO_OVERVIEW', datapointOverviewItem)

        dispatch('fetchTimeseries', [hashId])
      }
    }
  },

  separateByTimeseriesType: async ({ rootGetters }, hashIds: string[]): Promise<[string[], string[]]|undefined> => {
    const projectId = validateNotNullish(rootGetters['projects/currentProjectId'] as number|null)
    const project = validateNotNullish(rootGetters['projects/currentProject'] as Project|null)
    const hashIdsForAnalogTimeseries: string[] = []
    const hashIdsForBinaryTimeseries: string[] = []

    const timeseriesResponse: TimeseriesWithContext[] = await fetchProjectTimeseries(
      projectId,
      hashIds,
      undefined,
      undefined,
      undefined,
      new Date(Date.now()),
      undefined,
      false,
      1,
    )

    timeseriesResponse.forEach((timeseries: TimeseriesWithContext) => {
      if (timeseries.data!.length > 0) {
        const timeseriesType = getTypeOfTimeseriesValue(timeseries.data?.[0].value)
        const isBinary = isTimeseriesBinary(timeseries, timeseriesType)
        setDatapoint(project.handle!, timeseries.datapoint_hash_id!, isBinary, timeseriesType)
        if (isBinary) {
          hashIdsForBinaryTimeseries.push(timeseries.datapoint_hash_id!)
        } else {
          hashIdsForAnalogTimeseries.push(timeseries.datapoint_hash_id!)
        }
      } else {
        hashIdsForAnalogTimeseries.push(timeseries.datapoint_hash_id!)
      }
    })

    return [
      hashIdsForAnalogTimeseries,
      hashIdsForBinaryTimeseries,
    ]
  },

  setDateRange: ({ commit }, dateRange: [string, string?]) => {
    if (dateRange.length === 1) {
      commit('SET_DATE_RANGE', dateRange)
    } else {
      if (moment(dateRange[1]).utc().isBefore(moment(dateRange[0]).utc())) {
        commit('SET_DATE_RANGE', [dateRange[1], dateRange[0]])
      } else {
        commit('SET_DATE_RANGE', dateRange)
      }
    }
  },

  setSetpointEditorsDatapoint: ({ commit }, datapoint: DataPointWithContext|null) => {
    commit('SET_SETPOINTS_DATAPOINT_ID', datapoint?.hash_id ?? null)
    commit('SET_SETPOINT_DATAPOINT', datapoint)
  },

  setVisibilityOfAllTimeseries: ({ state }, visible: boolean) => {
    state.datapointTimeseries.forEach((item: DatapointIDAndTimeseries) => { item.visible = visible })
    state.datapointsOverview.forEach((item: DatapointOverviewItem) => { item.visible = visible })
  },

  toggleVisibilityOfTimeseries: ({ commit, state }, dataPointID: string) => {
    const foundDatapointIndexForTimeSeries: number = state.datapointTimeseries.findIndex((datapointAndTimeseries: DatapointIDAndTimeseries) => {
      return datapointAndTimeseries.dataPointID === dataPointID
    })

    const foundDatapointIndexForOverview: number = state.datapointsOverview.findIndex((datapointOverviewItem: DatapointOverviewItem) => {
      return datapointOverviewItem.dataPointID === dataPointID
    })

    if (foundDatapointIndexForTimeSeries >= 0 && foundDatapointIndexForOverview >= 0) {
      commit('TOGGLE_TIMESERIES_VISIBILITY', {
        dpIndexForOverview: foundDatapointIndexForOverview,
        dpIndexForTimeSeries: foundDatapointIndexForTimeSeries,
      })
    }
  },

  updateLiveViewRange: ({ commit }) => {
    const nowUtc = moment.utc().milliseconds(0)
    const start = moment(nowUtc).subtract(15, 'minutes').toDate()
    const end = nowUtc.toDate()
    commit('SET_LIVE_VIEW_RANGE', { end, start })
  },
} as ActionTree<DataPointsViewState, RootState>
