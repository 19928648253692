<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { NewTask, Task, UpdateTask } from '@aedifion.io/aedifion-api'
import ChecklistTable from './ChecklistTable.vue'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import { Language } from '@aedifion.io/pinia-aedifion-api-stores'
import moment from 'moment'
import PageHeader from '@/components/PageHeader.vue'
import { storeToRefs } from 'pinia'
import TaskDeleteDialog from './Tasks/TaskDeleteDialog.vue'
import TaskViewDialog from './Tasks/TaskViewDialog.vue'
import { useAppStore } from '@/stores/app'
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { useI18n } from 'vue-i18n'
import useIsCompanyAdmin from '@/composables/useIsCompanyAdmin'
import useIsReadonly from '@/composables/useIsReadOnly'

const appStore = useAppStore()
const checklistStore = useChecklistStore()

const { locale, t } = useI18n()
const { loading, sortedTasksTableRows } = storeToRefs(checklistStore)
const { isReadOnly } = useIsReadonly()
const { isCompanyAdmin } = useIsCompanyAdmin()

function onHandleNewTask () {
  showTask(null)
}

onMounted(() => {
  const payload = {
    language: locale.value as Language,
    projectId: appStore.projectId,
  }
  checklistStore.getTasks(payload)
})

const isTaskDialogOpen = ref(false)

const isDeleteDialogOpen = ref(false)
const selectedTask = computed<Task|null>(() => checklistStore.currentEditedTask)

function showTask (data: Task|null) {
  if (data) {
    checklistStore.setCurrentEditedTask(data)
  } else {
    checklistStore.clearCurrentEditedTask()
  }

  isTaskDialogOpen.value = true
}

function handleCloseDeleteDialog () {
  checklistStore.clearCurrentEditedTask()
  isDeleteDialogOpen.value = false
}

function deleteTask () {
  isDeleteDialogOpen.value = true
}

async function deleteTaskConfirmed () {
  await checklistStore.deleteTask({
    taskId: selectedTask.value!.id!,
  })
  isDeleteDialogOpen.value = false
  isTaskDialogOpen.value = false
}

function hasTaskBeenUpdated (newBody: UpdateTask) {
  const newAnalyticsResult = newBody.analytics_result?.[0]
  const newComponentInProject = newBody.componentinproject?.[0]
  const newTaskTitle = newBody.title
  const newTaskDescription = newBody.description
  const newTaskAssigneeId = newBody.assignee_user_id
  const newTaskStatus = newBody.status
  const newTaskPriority = newBody.priority
  const newStartDate = moment(newBody.start).format('YYYY-MM-DD')
  const newEndDate = moment(newBody.end).format('YYYY-MM-DD')

  return newStartDate !== (moment(selectedTask.value!.start).format('YYYY-MM-DD')) ||
    newEndDate !== (moment(selectedTask.value!.end).format('YYYY-MM-DD')) ||
    (newAnalyticsResult && (newAnalyticsResult !== selectedTask.value?.analytics_result?.[0]?.id)) ||
    (newComponentInProject && (newComponentInProject !== selectedTask.value?.componentinproject?.[0]?.id)) ||
    newTaskTitle !== selectedTask.value?.title ||
    newTaskDescription !== selectedTask.value?.description ||
    newTaskAssigneeId !== selectedTask.value?.assignee?.id ||
    newTaskStatus !== selectedTask.value?.status ||
    newTaskPriority !== selectedTask.value?.priority
}

function onUpdateTask (newBody: UpdateTask) {
  if (!hasTaskBeenUpdated(newBody)) {
    return
  }
  checklistStore.putTask({
    body: {
      ...newBody,
    },
    taskId: selectedTask.value!.id!,
  })
}

async function onCreateTask (task: NewTask) {
  isTaskDialogOpen.value = false
  await checklistStore.postTask({
    body: task,
    projectId: appStore.projectId,
  })
  checklistStore.getTasks({
    language: locale.value as Language,
    projectId: appStore.projectId,
  })
}

watch(() => appStore.projectId, () => {
  checklistStore.clearStore()
  checklistStore.getTasks({
    language: locale.value as Language,
    projectId: appStore.projectId,
  })
})
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      title-key="links.meta.title.optimization_checklist"
    >
      <v-btn
        v-if="(!loading && sortedTasksTableRows.length) && !isReadOnly"
        variant="outlined"
        color="primary-darken2"
        class="ml-auto text-primary btn-border tw-bg-white"
        @click="onHandleNewTask"
      >
        {{ t('new_task') }}
        <v-icon
          size="14"
          class="ml-3"
        >
          fa:far fa-circle-plus
        </v-icon>
      </v-btn>
    </PageHeader>
    <ChecklistTable
      v-show="sortedTasksTableRows.length || loading"
      :rows="sortedTasksTableRows"
      :loading="loading"
      @click-task="showTask"
    />
    <TaskViewDialog
      v-if="isTaskDialogOpen"
      :task="selectedTask"
      :value="isTaskDialogOpen"
      :is-read-only="isReadOnly"
      :is-company-admin="isCompanyAdmin"
      @update-task="onUpdateTask"
      @create-task="onCreateTask"
      @delete-task="deleteTask"
      @close="isTaskDialogOpen = false"
    />
    <TaskDeleteDialog
      v-if="isDeleteDialogOpen"
      :task="selectedTask"
      :value="isDeleteDialogOpen"
      @close="handleCloseDeleteDialog"
      @delete-task="deleteTaskConfirmed"
    />
    <EmptyStateForPage
      v-if="!loading && !sortedTasksTableRows.length"
      class="mt-n8"
      :icon="{
        name: 'fal fa-list-check',
        size: '40px',
        color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
      }"
      :title="t('empty_state.title')"
      :description="t('empty_state.description')"
    >
      <v-btn
        v-if="!isReadOnly"
        color="primary-darken2"
        class="align-center"
        height="40px"
        data-testid="add-task-button-empty-state"
        @click="onHandleNewTask"
      >
        <span class="mr-3">{{ t('empty_state.add_new_task') }}</span>
        <v-icon size="small">
          fal fa-circle-plus
        </v-icon>
      </v-btn>
    </EmptyStateForPage>
  </div>
</template>

<style lang="sass" scoped>
.btn-border
  border: 1px solid rgb(var(--v-theme-primary-lighten2))
</style>

<i18n lang="json" locale="de">
  {
  "empty_state": {
    "description": "Erstelle Aufgaben, um Aktionen zu verfolgen, zu sehen, wer daran arbeitet und wie der aktuelle Status ist.",
    "title": "Ein Neubeginn ...",
    "add_new_task": "Neue Aufgabe"
  },
  "new_task": "Neue Aufgabe"
  }
</i18n>

<i18n lang="json" locale="en">
  {
  "empty_state": {
    "description": "Create tasks to keep track of actions, see who’s working on it and what the current status is.",
    "title": "A fresh start ...",
    "add_new_task": "New task"
  },
  "new_task": "New task"
 }
</i18n>
