<script setup lang="ts">
import type { ShortUser, TaskComment } from '@aedifion.io/aedifion-api'
import { useDate } from 'vuetify'

const date = useDate()

defineProps<{
  comments: TaskComment[],
  loading: boolean
}>()

function getCommentAuthorName (author: ShortUser): string {
  return `${author?.firstName} ${author?.lastName ?? ''}`
}
</script>

<template>
  <v-container
    v-if="!loading"
    class="px-1"
  >
    <v-row
      v-for="comment in comments"
      :key="comment.id"
      class="tw-flex-nowrap"
    >
      <v-col
        class="py-2 tw-min-w-[160px] tw-max-w-[160px] tw-flex-grow-0 text-neutral-darken1"
      >
        <v-icon
          size="14"
          class="mr-2"
        >
          fa:far fa-message-lines
        </v-icon>
        {{ date.format(comment.created, 'normalDateWithWeekday') }}
      </v-col>
      <v-col class="py-2 text">
        <span class="tw-break-normal">{{ comment.text }}</span>
        <span class="text-neutral-darken1 mt-2 d-block">{{ getCommentAuthorName(comment.author!) }}</span>
      </v-col>
    </v-row>
  </v-container>
  <div
    v-else-if="loading"
    class="mt-n4"
  >
    <div
      v-for="i in 5"
      :key="i"
      class="tw-flex"
    >
      <div class="tw-flex">
        <v-skeleton-loader
          type="image"
          class="tw-rounded-none tw-overflow-hidden tw-bg-transparent tw-ml-1 tw-my-auto tw-grow-0"
          width="14"
          height="14"
        />
        <v-skeleton-loader
          type="text"
          class="tw-my-auto"
          width="80"
          height="50"
        />
      </div>
      <v-skeleton-loader
        type="sentences"
        class="tw-ml-12"
        width="406"
      />
    </div>
  </div>
</template>
