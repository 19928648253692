<!--
DEPRECATED

Please use StyledDatepicker.vue when possible.
-->

<script setup lang="ts">
import { computed, ref } from 'vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'

type Props = {
  disabled?: boolean,
  value?: [start: string, end: string] | [string]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  value: () => [
    moment.utc().subtract(7, 'd').toISOString(),
    moment.utc().toISOString(),
  ],
})

const emit = defineEmits<{
  (e: 'datepicker:change', value: [string, string] | [string]): void
}>()

const { t } = useI18n()

const dates = ref<[Date, Date]|[Date]>(convertStringToDate(props.value))
const menu = ref(false)

const datesText = computed<string>(() => {
  if (props.value.length === 1) {
    return moment(props.value[0]).format(t('dates.date_format'))
  }
  const start = moment(props.value[0]).format(t('dates.date_format'))
  const end = moment(props.value[1]).format(t('dates.date_format'))
  return t('from_to', { end, start })
})

const maxDateAllowed = new Date()

function cancel (): void {
  dates.value = convertStringToDate(props.value)
  menu.value = false
}

function sortCachedDates (): void {
  if (dates.value.length === 2) {
    const dateA = moment(dates.value[0]).utc(true)
    const dateB = moment(dates.value[1]).utc(true)
    if (moment(dateB).isBefore(moment(dateA))) {
      dates.value = [dateB.toDate(), dateA.toDate()]
    }
  }
}

function commitSelection (): void {
  const convertedDates = convertDateToString(dates.value)
  if ((convertedDates[0] !== props.value[0]) || (convertedDates[1] !== props.value[1])) {
    sortCachedDates()
    if (dates.value[0] === dates.value?.[1]) {
      emit('datepicker:change', convertDateToString([dates.value[0]]))
    } else {
      emit('datepicker:change', [convertedDates[0], convertedDates[convertedDates.length - 1]])
    }
  }

  menu.value = false
}

function convertStringToDate (date: [start: string, end: string] | [string]): [start: Date, end: Date] | [Date] {
  return date.map((d) => moment(d).toDate()) as [Date, Date]| [Date]
}

function convertDateToString (date: [start: Date, end: Date] | [Date]): [start: string, end: string] | [string] {
  return date.map((d) => moment(d).format('YYYY-MM-DD')) as [start: string, end: string] | [string]
}
</script>

<template>
  <div class="mb-0 pb-0 tw-w-[290px]">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :disabled="props.disabled"
      min-width="auto"
      content-class="tw-text-red-700"
      location="bottom"
      transition="scale-transition"
    >
      <template #activator="{ props: menuProps }">
        <v-text-field
          v-model="datesText"
          v-bind="menuProps"
          data-testid="datepicker-activator"
          density="compact"
          :disabled="props.disabled"
          hide-details
          :label="t('dates.time_period')"
          variant="outlined"
          prepend-inner-icon="fa:far fa-calendar-week"
          readonly
        />
      </template>
      <v-date-picker
        v-model="dates"
        multiple="range"
        hide-header
        :max="maxDateAllowed"
        color="primary-darken2"
        width="auto"
        data-testid="datepicker-table"
      >
        <template #actions>
          <v-btn
            color="primary-darken2"
            data-testid="datepicker-cancel"
            variant="text"
            @click="cancel"
          >
            {{ t('actions.cancel') }}
          </v-btn>
          <v-btn
            color="primary-darken2"
            data-testid="datepicker-ok"
            variant="elevated"
            @click="commitSelection"
          >
            {{ t('actions.ok') }}
          </v-btn>
        </template>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<style lang="sass" scoped>
:deep(.v-field__field)
  input
    cursor: pointer !important
</style>

<i18n locale="de">
  {
    "from_to": "{start} bis {end}"
  }
  </i18n>
  <i18n locale="en">
  {
    "from_to": "{start} to {end}"
  }
  </i18n>
