import { acceptHMRUpdate, defineStore } from 'pinia'
import { GetKpiAggregationPayload, useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import type { KPIItem } from './types'
import { computed, ref } from 'vue'
import { reportError } from '@/utils/helpers/errors'
import vuexStore from '@/vuex'
import { useUserStore } from '@/stores/user'
import { User } from '@aedifion.io/aedifion-api'
import { getDateRangeForKPI, replaceMonthlyWithYearly } from '../AssetOverview'
import i18n from '@/i18n'

export const useOverviewStore = defineStore('overview', () => {
  const analyticsApiStore = useAnalyticsApiStore()
  const userStore = useUserStore()

  const user = computed<User>(() => userStore.userDetails!)
  const energyKpiAggregation = ref<KPIItem[]>([])
  const productivityKpiAggregation = ref<KPIItem[]>([])
  const loadingEnergyConsumptionKPI = ref(false)
  const loadingProductivityKPI = ref(false)

  async function fetchEnergyConsumptionKpi (projectIds: number[]) {
    loadingEnergyConsumptionKPI.value = true

    const payload: GetKpiAggregationPayload = {
      kpi: 'energy_consumption',
      projectAggregation: 'mean',
      projectIds,
      timeAggregation: 'sum',
      timeWindow: 'P1Y',
      currencySystem: user.value?.currency_system,
      unitsSystem: user.value?.units_system,
    }
    try {
      const result = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
      const projectResults = result?.project_results
      if (projectResults) {
        for (const project of projectResults) {
          if (project?.time_range_info?.filled) {
            const newItem: KPIItem = {
              aggregationValue: project?.aggregation_value,
              endDate: project?.time_range_info?.end,
              filled: project?.time_range_info?.filled,
              projectId: project?.project?.id,
              startDate: project?.time_range_info?.start,
              tooltipText: getDateRangeForKPI(project?.time_range_info),
              unit: replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', result.units)?.symbol),
            }
            energyKpiAggregation.value.push(newItem)
          } else {
            const payload: GetKpiAggregationPayload = {
              kpi: 'energy_consumption',
              projectAggregation: 'mean',
              projectIds: [project.project.id!],
              timeAggregation: 'mean',
              timeWindow: 'P1Y',
              currencySystem: user.value?.currency_system,
              unitsSystem: user.value?.units_system,
            }
            const result = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
            if (result?.project_results) {
              const newItem: KPIItem = {
                aggregationValue: result.project_results[0].aggregation_value! * 12,
                endDate: result?.project_results[0]?.time_range_info?.end,
                filled: result?.project_results[0]?.time_range_info?.filled,
                projectId: result?.project_results[0]?.project?.id,
                startDate: result?.project_results[0]?.time_range_info?.start,
                tooltipText: i18n.global.t('asset_overview.extrapolated_data'),
                unit: replaceMonthlyWithYearly(vuexStore.getters['labels/label']('units', result.units)?.symbol),
              }
              energyKpiAggregation.value.push(newItem)
            }
          }
        }
      }
    } catch (error) {
      reportError(error)
    } finally {
      loadingEnergyConsumptionKPI.value = false
    }
  }

  async function fetchProductivityKpi (projectIds: number[]) {
    loadingProductivityKPI.value = true

    const payload: GetKpiAggregationPayload = {
      kpi: 'productivity',
      projectAggregation: 'mean',
      projectIds,
      timeAggregation: 'mean',
      timeWindow: 'P1Y',
      currencySystem: user.value?.currency_system,
      unitsSystem: user.value?.units_system,
    }

    try {
      const result = await analyticsApiStore.getKpiAggregation(payload, { storeResult: false })
      const projectResults = result.project_results!
      for (const project of projectResults) {
        const newItem: KPIItem = {
          aggregationValue: project?.aggregation_value,
          endDate: project?.time_range_info?.end,
          filled: project?.time_range_info?.filled,
          projectId: project?.project?.id,
          tooltipText: getDateRangeForKPI(project?.time_range_info),
          startDate: project?.time_range_info?.start,
          unit: vuexStore.getters['labels/label']('units', result.units)?.symbol,
        }
        productivityKpiAggregation.value.push(newItem)
      }
    } catch (error) {
      reportError(error)
    } finally {
      loadingProductivityKPI.value = false
    }
  }

  return {
    energyKpiAggregation,
    fetchEnergyConsumptionKpi,
    fetchProductivityKpi,
    loadingEnergyConsumptionKPI,
    loadingProductivityKPI,
    productivityKpiAggregation,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverviewStore, import.meta.hot))
}
