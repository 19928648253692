<script setup lang="ts">
import ActionMenu, { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { Alignment, ColumnHeader, RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { computed, onBeforeMount, reactive, ref, watch, watchEffect } from 'vue'
import { type Meter, type MeterReadings } from '@/vuex/asset_overview/types'
import AedifionDisclaimer from '@/components/AedifionDisclaimer.vue'
import { COCKPIT_NUDGE_GRADIENT } from '@theme/colors'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import DateOrMonthPicker from '@/components/DateOrMonthPicker.vue'
import EmptyStateForPage from '@/components/EmptyStateForPage.vue'
import { getUserCurrencySymbol } from '@/filters/formatting'
import KebabMenu from '@/components/KebabMenu.vue'
import MeterEditor from '@/views/Meter/MeterEditor.vue'
import moment from 'moment'
import PageHeader from '@/components/PageHeader.vue'
import ReadingsTable from '@/views/Meter/ReadingsTable.vue'
import SummarizedTable from '@/components/SummarizedTable.vue'
import TrendView from '@/components/TrendView.vue'
import UploadCsvFileDialog from './UploadCsvFileDialog.vue'
import { useAppStore } from '@/stores/app'
import { useI18n } from 'vue-i18n'
import useisReadOnly from '@/composables/useIsReadOnly'
import { useMeterStore } from '@/stores/views/Meter'
import vuexStore from '@/vuex'
import { resetStoreState } from '@/vuex/asset_overview/state'

// #region INITIALIZATION OF STORES AND COMPOSABLES
const { t } = useI18n()
const { isReadOnly } = useisReadOnly()

const meterStore = useMeterStore()

const appStore = useAppStore()
// #endregion

// #region ACTION DROPDOWN
if (meterStore.csvFileTemplateUrl === null) {
  meterStore.fetchCsvFileTemplateUrl()
}

const actionItems = computed<ActionMenuItem[]>(() => {
  const items: ActionMenuItem[] = [{
    icon: 'fa:far fa-plus',
    id: 'add-meter',
    label: t('add_analog_meter'),
  }, {
    icon: 'fa:far fa-upload',
    id: 'upload-readings',
    label: t('action_drop_down.upload_meter_readings'),
  }]

  if (meterStore.csvFileTemplateUrl) {
    items.push({
      divider: true,
      download: 'template.csv',
      href: meterStore.csvFileTemplateUrl,
      icon: 'fa:far fa-download',
      id: 'download-template',
      label: t('action_drop_down.download_template'),
    })
  }

  return items
})
// #endregion

// #region FETCHING METER DATA AND TABLE HEADER
if (meterStore.componentsKpiAggregation === null) {
  meterStore.fetchMeterData()
}

if (vuexStore.state.asset_overview.userMeters === null) {
  vuexStore.dispatch('asset_overview/fetchUserMeters')
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
watch(([() => meterStore.dateRange, () => appStore.projectId]), async ([_oldDateRange, oldProjectID], [_newDateRange, newProjectID]) => {
  meterStore.fetchMeterData()
  if (oldProjectID !== newProjectID) {
    meterStore.fetchCsvFileTemplateUrl()
    resetStoreState(vuexStore.state.asset_overview)
    await vuexStore.dispatch('asset_overview/fetchUserMeters')
  }
})

function fetchMeterTimeseries (meterId: number) {
  const meter: Meter[] = vuexStore.getters['asset_overview/userMeters'].find((meter: Meter) => meter.id === meterId)!
  vuexStore.dispatch('asset_overview/fetchMeterReadings', meter)
}

const userMeters = computed<Meter[]>(() => vuexStore.getters['asset_overview/userMeters'])
const isLoadingUserMeters = computed<boolean>(() => vuexStore.state.asset_overview.loadingUserMeters)

const readingsForSelectedMeter = computed<MeterReadings>(() => {
  if (selectedAnalogMeter.value) {
    return vuexStore.getters['asset_overview/readingsForSelectedMeter']
  }
  return {}
})

const isLoadingMeterReadings = computed<boolean>(() => vuexStore.state.asset_overview.loadingMeterReadings)

const selectedMeter = computed<Meter|null>(() => vuexStore.state.asset_overview.selectedUserMeter)

const selectedAnalogMeter = computed<Meter|null>(() => {
  if (vuexStore.state.asset_overview.selectedUserMeter?.type === 'Analog') {
    return vuexStore.state.asset_overview.selectedUserMeter
  }
  return null
})

const isMeterAnalysisAvailable = computed<boolean>(() => {
  if (selectedAnalogMeter.value) {
    return vuexStore.getters['asset_overview/userMeterAnalysisIsAvailable']
  }
  return false
})

type Keys = 'component'| 'type' | 'cost' | 'co2' | 'energy' | 'action'

/**
 * TODO: When different units are supported, make the units dynamic based on the user's settings
 */
const headers = computed<ColumnHeader<Keys>[]>(() => [
  {
    key: 'component',
    sortOptions: [SortDirection.DESC, SortDirection.ASC],
    text: t('header.meter_name'),
    tooltip: t('header.meter_name'),
    width: Width.MaxContent,
  }, {
    key: 'type',
    sortOptions: [SortDirection.DESC, SortDirection.ASC],
    text: t('header.type_text'),
    tooltip: t('header.type_text'),
    width: Width.Grow,
  },
  {
    align: Alignment.Right,
    key: 'cost',
    sortOptions: [SortDirection.DESC, SortDirection.ASC],
    text: t('header.costs_text'),
    tooltip: t('header.costs_tooltip'),
    unit: getUserCurrencySymbol(),
    width: Width.MaxContent,
  }, {
    align: Alignment.Right,
    key: 'co2',
    sortOptions: [SortDirection.DESC, SortDirection.ASC],
    text: t('header.co2_text'),
    tooltip: t('header.co2_tooltip'),
    unit: 'kg',
    width: Width.MaxContent,
  }, {
    align: Alignment.Right,
    key: 'energy',
    sortOptions: [SortDirection.DESC, SortDirection.ASC],
    text: t('header.energy_text'),
    tooltip: t('header.energy_tooltip'),
    unit: 'kWh',
    width: Width.MaxContent,
  },
  {
    key: 'action',
    text: '',
    width: Width.MaxContent,
  },
])

function rowHasDataForKPIs (row: RowData<Keys>): boolean {
  return Object.values(headers.value).filter(header => header.key !== 'component' && header.key !== 'type').some(header => row[header.key] !== undefined)
}

const showHeaders = computed<boolean>(() => {
  if (meterStore.componentsKPIs.length === 1) {
    return rowHasDataForKPIs(meterStore.componentsKPIs[0])
  }

  return meterStore.meterSummary !== null
})

const summarizedTable = ref<InstanceType<typeof SummarizedTable> | null>(null)

const kebabMenuItems = computed<ActionMenuItem[]>(() => {
  if (isReadOnly.value) {
    return [{
      icon: 'fa:far fa-eye',
      id: 'view-readings',
      label: t('edit_menu_items.view_meter_readings'),
    }]
  }
  return [{
    icon: 'fa:far fa-eye',
    id: 'view-readings',
    label: t('edit_menu_items.view_meter_readings'),
  },
  {
    icon: 'fa:far fa-pen',
    id: 'edit',
    label: t('edit_menu_items.edit_meter'),
  },
  {
    icon: 'fa:far fa-trash',
    id: 'delete',
    label: t('edit_menu_items.delete_meter'),
  }]
})

async function onViewMeterReadingsClicked (meter: { isAnalogMeter: boolean, meterId: number }): Promise<void> {
  await showMeterPlotOrReadings(meter.meterId, meter.isAnalogMeter)
}

async function onDeleteMeterClicked (meter: { meterId: number }): Promise<void> {
  setSelectedUserMeter(meter.meterId)
  meterDialogs.delete = true
}

const shouldShowMeterTable = computed<boolean>(() => {
  return !meterStore.isLoadingComponentsKpiAggregation && meterStore.componentsKPIs.length > 0 && !isLoadingUserMeters.value
})
// #endregion

// #region METER DIALOGS FUNCTIONALITY
const meterDialogs = reactive({
  addOrEdit: false,
  delete: false,
  plotting: false,
  readings: false,
  uploadCsvFile: false,
})

watch([() => meterDialogs.plotting, () => meterDialogs.readings], ([newPlottingDialogVal, newReadingsDialogVal], [
  oldPlottingDialogVal, oldReadingsDialogVal]) => {
  if ((oldPlottingDialogVal === true && newPlottingDialogVal === false) ||
   (newReadingsDialogVal === false && oldReadingsDialogVal === true)) {
    deselectMeter()
  }
})

function setSelectedUserMeter (id: number, fetchPlotData?: boolean) {
  const meter = userMeters.value.find(meter => meter.id === id)
  if (!meter) {
    throw new Error(`Meter with id ${id} was not found`)
  }
  vuexStore.commit('asset_overview/SET_SELECTED_USER_METER', meter)
  if (fetchPlotData) {
    vuexStore.dispatch('optimization/selectComponentInProject', id)
  }
}

function deselectMeter () {
  vuexStore.commit('asset_overview/SET_SELECTED_USER_METER', null)
  vuexStore.commit('optimization/SET_SELECTED_COMPONENT_IN_PROJECT', null)
}

async function showMeterPlotOrReadings (meterID: number, isAnalogMeter: boolean, isEditing: boolean = false) {
  setSelectedUserMeter(meterID, !isAnalogMeter)
  fetchMeterTimeseries(meterID)
  openDialogBasedOnMeterType(isAnalogMeter, isEditing)
}

async function handleDeleteMeter () {
  await vuexStore.dispatch('asset_overview/deleteUserMeter', selectedMeter.value)
  meterDialogs.delete = false
}

function handleAddMeter () {
  deselectMeter()
  meterDialogs.addOrEdit = true
}

function handleUploadCsvFile () {
  meterDialogs.uploadCsvFile = true
}

function handleCloseMeterDialog (dialog: 'addOrEdit' | 'delete' | 'readings' | 'plotting' | 'uploadCsvFile') {
  meterDialogs[dialog] = false
  deselectMeter()
}

function openDialogBasedOnMeterType (isAnalogMeter: boolean, isEditing: boolean = false) {
  if (isEditing) {
    meterDialogs.addOrEdit = true
    return
  }

  if (isAnalogMeter) {
    meterDialogs.readings = true
  } else {
    meterDialogs.plotting = true
  }
}

onBeforeMount(() => {
  deselectMeter()
})
// #endregion

// #region AEDIFION DISCLAIMER
if (!sessionStorage.getItem('hasTheMeterDisclaimerBeenConsented')) {
  sessionStorage.setItem('hasTheMeterDisclaimerBeenConsented', 'false')
}
const disclaimerState = ref<boolean>(
  sessionStorage.getItem('hasTheMeterDisclaimerBeenConsented') === 'false',
)

watchEffect(() => {
  if (disclaimerState.value === false) {
    sessionStorage.setItem('hasTheMeterDisclaimerBeenConsented', 'true')
  }
})
// #endregion

// #region MONTH_DATE_PICKER FUNCTIONALITY
const menu = ref(false)

const MAX_DATE = moment().utc().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')

function handleUpdateDate (date: [start: Date, end: Date]) {
  meterStore.dateRange = date
}
// #endregion
</script>

<template>
  <div class="layout-wrapper">
    <PageHeader
      sticky
      :title-key="t('links.meta.title.meter')"
      column
    >
      <div
        v-if="shouldShowMeterTable"
        class="d-flex mt-11"
      >
        <DateOrMonthPicker
          class="mr-auto"
          :text="t('dates.time_period')"
          width="auto"
          :date="meterStore.dateRange"
          :menu="menu"
          range
          :max-date="MAX_DATE"
          :nudge-bottom="8"
          @update:menu="menu = $event"
          @update:date="handleUpdateDate($event)"
        />
        <ActionMenu
          v-if="!isReadOnly"
          :items="actionItems"
          data-testid="add-meter-button"
          left
          offset-direction="y"
          @add-meter-option-click="handleAddMeter"
          @upload-readings-option-click="handleUploadCsvFile"
        >
          <template #default="menuProps">
            <v-btn
              v-bind="menuProps"
              color="primary-lighten3"
            >
              <span class="mr-3 text-primary-darken2">{{ t('action_drop_down.more_actions') }}</span>
              <v-icon
                :class="[
                  'dropdown-activator-arrow',
                  {
                    'active': menuProps.isOpen
                  }
                ]"
                color="primary-darken2"
                size="small"
              >
                fa:far fa-angle-down
              </v-icon>
            </v-btn>
          </template>
        </ActionMenu>
      </div>
      <div
        v-else-if="meterStore.isLoadingComponentsKpiAggregation || isLoadingUserMeters"
        class="d-flex mt-11"
      >
        <v-skeleton-loader
          type="image"
          width="180px"
          class="bg-transparent overflow-hidden"
          height="40px"
        />
        <v-skeleton-loader
          type="text@1"
          class="mt-3 ml-auto bg-transparent"
          width="220px"
        />
      </div>
    </PageHeader>
    <SummarizedTable
      v-if="shouldShowMeterTable"
      ref="summarizedTable"
      :headers="headers"
      :show-headers="showHeaders"
      :summary="meterStore.meterSummary"
      :rows="meterStore.componentsKPIs"
      :default-sorting="{
        key: 'cost',
        direction: SortDirection.DESC
      }"
      :fallback-cell-span-start-index="2"
      :fallback-cell-span-count="3"
    >
      <template #cell.fallback="{row, rowIndex}">
        <div
          v-if="row.type.text === 'Analog'"
          class="d-flex align-center justify-center ml-auto h-full"
          :data-testid="`enter-meter-reading-${rowIndex}`"
        >
          <span class="ml-auto mr-4 text-subtitle-2 font-weight-regular text-neutral-darken1">{{ t('meter_view.enter_meter_reading') }}</span>
          <v-btn
            v-if="!isReadOnly"
            color="primary-darken2"
            class="d-flex align-center"
            height="40px"
            @click="showMeterPlotOrReadings(row.component.id, true)"
          >
            <span class="mr-3">{{ t('add_meter_reading') }}</span>
            <v-icon size="small">
              fa:far fa-plus-circle
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template #cell.action="{row, rowIndex}">
        <div
          :data-testid="`action-cell-${rowIndex}`"
          class="d-flex justify-end flex-grow-1 align-center"
        >
          <v-btn
            v-if="!row.type.custom.isAnalogMeter"
            variant="text"
            class="mr-1"
            icon
            @click="showMeterPlotOrReadings(row.component.id, row.type.custom.isAnalogMeter)"
          >
            <v-icon
              color="primary-darken2"
              size="16"
            >
              fa:far fa-chart-line
            </v-icon>
          </v-btn>
          <KebabMenu
            :custom-event-data="{ isAnalogMeter: row.type.custom.isAnalogMeter, meterId: row.component.id }"
            :items="kebabMenuItems"
            color="primary-darken2"
            :left="true"
            offset-direction="y"
            @view-readings-option-click="onViewMeterReadingsClicked"
            @edit-option-click="showMeterPlotOrReadings(row.component.id, row.type.custom.isAnalogMeter, true)"
            @delete-option-click="onDeleteMeterClicked"
          />
        </div>
      </template>
    </SummarizedTable>
    <div
      v-else-if="meterStore.isLoadingComponentsKpiAggregation || isLoadingUserMeters"
      data-testid="summarized-table-skeleton"
      class="mt-n4"
    >
      <v-row
        class="d-flex flex-row justify-start content-start mr-2"
      >
        <v-col
          v-for="(index) in 5"
          :key="index"
          cols="2"
        >
          <v-skeleton-loader
            type="text"
            class="mr-auto bg-transparent"
            width="80%"
          />
        </v-col>
      </v-row>
      <v-skeleton-loader
        type="table-row"
        class="v-sheet px-4 mb-2 summarized-table__skeleton-loader"
      />
      <v-sheet
        v-for="i in 20"
        :key="i"
      >
        <v-skeleton-loader
          type="table-row"
          class="px-4 summarized-table__skeleton-loader"
        />
      </v-sheet>
    </div>
    <EmptyStateForPage
      v-else
      class="-tw-mt-32"
      :icon="{
        name: 'fa:far fa-meter',
        size: '40px',
        color: `linear-gradient(${COCKPIT_NUDGE_GRADIENT[0]}, ${COCKPIT_NUDGE_GRADIENT[1]}, ${COCKPIT_NUDGE_GRADIENT[2]})`
      }"
      :title="t('empty_state.title')"
      :description="t('empty_state.description')"
    >
      <v-btn
        color="primary-darken2"
        class="d-flex"
        height="40px"
        data-testid="add-meter-button-empty-state"
        @click="handleAddMeter"
      >
        <span class="mr-3">{{ t('add_analog_meter') }}</span>
        <v-icon
          size="small"
        >
          fa:far fa-plus-circle
        </v-icon>
      </v-btn>
    </EmptyStateForPage>
    <MeterEditor
      :key="`meter-editor-${selectedMeter?.id}`"
      :value="meterDialogs.addOrEdit"
      :meter="selectedMeter"
      @meter-editor:close="handleCloseMeterDialog('addOrEdit')"
    />
    <UploadCsvFileDialog
      :is-open="meterDialogs.uploadCsvFile"
      @close="handleCloseMeterDialog('uploadCsvFile')"
    />
    <v-dialog
      v-model="meterDialogs.readings"
      width="740px"
      data-testid="analog-meter-readings-dialog"
    >
      <ReadingsTable
        show-cancel-button
        :loading="isLoadingMeterReadings"
        :meter="selectedAnalogMeter"
        :meter-analysis-available="isMeterAnalysisAvailable"
        :read-only="isReadOnly"
        :readings="readingsForSelectedMeter"
        @close-dialog="handleCloseMeterDialog('readings')"
      />
    </v-dialog>
    <ConfirmationDialog
      v-if="meterDialogs.delete"
      v-model="meterDialogs.delete"
      accept-button-text-key="actions.delete"
      :required-input="selectedMeter.meterName"
      :title="t('meter_view.delete_meter_confirmation_title')"
      @confirmation-dialog:accept="handleDeleteMeter"
      @confirmation-dialog:cancel="handleCloseMeterDialog('delete')"
    >
      <span class="mb-4 d-block">{{ t('meter_view.delete_meter_confirmation_text') }}</span>
      <template #warning>
        <p class="mb-2">
          {{ t('delete_meter_confirmation.additional_data') }}
          <ul class="tw-list-disc">
            <li>{{ t('delete_meter_confirmation.attributes') }}</li>
            <li>{{ t('delete_meter_confirmation.historical_measurements') }}</li>
          </ul>
        </p>
        <p
          class="mb-0"
          v-html="t('delete_meter_confirmation.conclusion')"
        />
      </template>
    </ConfirmationDialog>
    <v-dialog
      v-model="meterDialogs.plotting"
      width="1366px"
      height="630px"
      data-testid="digital-meter-plotting-dialog"
    >
      <v-sheet>
        <v-card-title class="text-h6 neutral-lighten3 mb-n4 d-flex">
          <span class="my-auto">{{ t('digital_meter_plotting') }}</span>
          <v-btn
            icon
            class="ml-auto"
            @click="handleCloseMeterDialog('plotting')"
          >
            <v-icon
              size="20"
            >
              fa:fal fa-xmark
            </v-icon>
          </v-btn>
        </v-card-title>
        <TrendView
          :show-datapoints-table="false"
          class="pt-4"
        />
      </v-sheet>
    </v-dialog>
    <AedifionDisclaimer
      v-model="disclaimerState"
      :is-loading="false"
      :title="t('disclaimer.title')"
      :text="t('disclaimer.text')"
      :button-text="t('disclaimer.ok_button_text')"
      icon="fa:fal fa-info-circle"
    />
  </div>
</template>

<i18n lang="json" locale="de">
  {
    "disclaimer": {
      "title": "Hauptzähler",
      "text": "Um eine Vergleichbarkeit zwischen Projekten sicherzustellen, werden hier nur die Hauptzähler zusammengefasst. Die Liste kann auch Unterzähler enthalten, die gesondert gekennzeichnet sind.",
      "ok_button_text": "Ok, verstanden!"
    },
    "add_analog_meter": "Analogen Zähler hinzufügen",
    "action_drop_down": {
      "more_actions": "Weitere Aktionen",
      "upload_meter_readings": "Zählerdaten hochladen",
      "download_template": "Vorlage runterladen"
    },
    "header": {
      "meter_name": "Zählername",
      "co2_text": "CO<sub>2</sub>",
      "co2_tooltip": "CO<sub>2</sub>-Emissionen",
      "costs_text": "Kosten",
      "costs_tooltip": "Energiekosten",
      "energy_text": "Energie",
      "energy_tooltip": "Energieverbrauch",
      "type_text": "Typ"
    },
    "add_meter_reading": "Zählerstand hinzufügen",
    "edit_menu_items": {
      "view_meter_readings": "Zählerstand anzeigen",
      "edit_meter": "Zähler bearbeiten",
      "delete_meter": "Zähler löschen"
    },
    "digital_meter_plotting": "Digitale Zähler Visualisierung",
    "empty_state": {
      "title": "Hast Du Zählerstände?",
      "description": "Digitalisiere sie, indem Du Deinen ersten Zähler hinzufügst und erhalte KPIs für Dein Gebäude!"
    },
    "delete_meter_confirmation": {
      "additional_data": "Zusätzlich zum Zähler werden auch diese gelöscht:",
      "attributes": "Attribute",
      "historical_measurements": "Historische Messungen",
      "conclusion": "Gelöschene Messungen können nicht wiederhergestellt werden."
    }
  }
</i18n>

<i18n lang="json" locale="en">
{
  "disclaimer": {
    "title": "Main Meter",
    "text": "To ensure comparability between projects, only the main meters are summarized here. The list may also contain submeters, which are marked separately.",
    "ok_button_text": "Ok, got it!"
  },
  "add_analog_meter": "Add analog meter",
  "action_drop_down": {
    "more_actions": "More actions",
    "upload_meter_readings": "Upload meter readings",
    "download_template": "Download template"
  },
  "header": {
      "meter_name": "Meter name",
    "co2_text": "CO<sub>2</sub>",
    "co2_tooltip": "CO<sub>2</sub> emissions",
    "costs_text": "Costs",
    "costs_tooltip": "Energy cost",
    "energy_text": "Energy",
    "energy_tooltip": "Energy consumption",
    "type_text": "Type"
  },
  "add_meter_reading": "Add meter reading",
  "edit_menu_items": {
    "view_meter_readings": "View meter readings",
    "edit_meter": "Edit meter",
    "delete_meter": "Delete meter"
  },
  "digital_meter_plotting": "Digital Meter Plotting",
  "empty_state": {
    "title": "Do You Have Meter Readings?",
    "description": "Digitize them by adding your first meter and get KPIs for your building!"
  },
  "delete_meter_confirmation": {
    "additional_data": "In addition to the meter, these will be deleted as well:",
    "attributes": "Attributes",
    "historical_measurements": "Historical measurements",
    "conclusion": "Deleted measurements can not be restored."
  }
}
</i18n>

<style lang="sass" scoped>
.dropdown-activator-arrow
  transition: transform .3s ease-in-out
  &.active
    transform: rotate(0.5turn)

.d-contents
  display: contents
.summarized-table__skeleton-loader
  padding-top: 3px
  padding-bottom: 3px

[data-testid="summarized-table-skeleton"] > .v-sheet
  &:nth-child(n+2)
    border-radius: 0 !important
    border-bottom: 0
  &:nth-child(2)
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
    border-radius: 4px !important
  &:nth-child(3)
    border-top-right-radius: 4px !important
    border-top-left-radius: 4px !important
  &:last-child
    border-radius: 4px
    border-top-right-radius: 0
    border-top-left-radius: 0
    border-bottom-right-radius: 4px !important
    border-bottom-left-radius: 4px !important
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important

</style>
