<i18n locale="de">
{
  "live_view": "Live View"
}
</i18n>
<i18n locale="en">
{
  "live_view": "Live View"
}
</i18n>

<template>
  <v-sheet class="mt-3 pt-4 px-2">
    <div class="graph-settings__wrapper mb-4">
      <div>
        <SamplerateSelect
          :value="samplerate"
          :disabled="!hasPlottedDatapoints"
          @update:value="updateSamplerate"
        />
      </div>
      <div class="d-flex justify-center flex-row align-center">
        <v-btn
          class="mr-2"
          color="primary-darken2"
          :disabled="!canUseLiveView"
          variant="text"
          @click="toggleLiveView"
        >
          <v-icon
            start
            size="small"
          >
            {{ liveViewActive ? 'fa:far fa-stop' : 'fa:far fa-play' }}
          </v-icon>
          {{ t('live_view') }}
        </v-btn>
        <PlottingDateRangePicker
          :disabled="liveViewActive"
          :value="dateRange"
          @datepicker:change="setDateRange"
        />
      </div>
    </div>
    <TimeseriesViewer
      :custom-line-chart-options="customChartOptions"
      :is-zoomed="!!zoom"
      :loading="isLoadingTimeseries"
      :series="series"
      :x-max="effectiveRange.end"
      :x-min="effectiveRange.start"
      @timeseries-viewer:reset-zoom="zoomChart"
      @timeseries-viewer:zoom="zoomChart"
    />
  </v-sheet>
</template>

<script lang="ts">
import { DatapointIDAndTimeseries, Samplerate } from '@/vuex/data_points_view/types'
import { createNamespacedHelpers } from 'vuex'
import { defineComponent } from 'vue'
import PlottingDateRangePicker from '@/components/PlottingDateRangePicker.vue'
import { RouteLocationNormalized } from 'vue-router'
import SamplerateSelect from '@/components/SamplerateSelect.vue'
import TimeseriesViewer from '@/components/Charts/TimeseriesViewer.vue'
import { useI18n } from 'vue-i18n'
import { ZoomParameters } from '@/components/Charts/types'
const { mapGetters, mapState } = createNamespacedHelpers('data_points_view')

export default defineComponent({
  name: 'Visualization',

  components: {
    PlottingDateRangePicker,
    SamplerateSelect,
    TimeseriesViewer,
  },

  setup () {
    const { t } = useI18n()
    return { t }
  },

  data () {
    return {
      dates: [] as string[],
      ignoreZoomEvents: false,
      liveViewTimerHandle: null as ReturnType<typeof setTimeout>|null,
    }
  },

  computed: {
    ...mapGetters({
      dateRange: 'getDateRange',
      effectiveRange: 'getEffectiveRange',
      selectedDatapointHashIds: 'getSelectedDatapointsHashIds',
      zoom: 'zoom',
    }),

    ...mapState({
      liveViewActive: 'liveViewActive',
      overviewItems: 'datapointsOverview',
    }),

    canUseLiveView (): boolean {
      return this.selectedDatapointHashIds.length > 0
    },

    customChartOptions (): unknown {
      return {
        lang: {
          noData: this.t('data_points_view.select_datapoint') as string,
        },
      }
    },

    hasPlottedDatapoints (): boolean {
      return this.overviewItems.length > 0
    },

    isLoadingTimeseries (): boolean {
      // When live view is active the "Data loading" shouldn't be displayed in the chart
      return !this.liveViewActive && this.$store.getters['data_points_view/isLoadingDatapointTimeseries']
    },

    samplerate (): Samplerate {
      return this.$store.state.data_points_view.samplerate
    },

    series (): unknown[] {
      return this.$store.getters['data_points_view/getDatapointTimeseries'].map((datapointAndTimeseries: DatapointIDAndTimeseries) => {
        let unitLabel = null
        if (datapointAndTimeseries.unitLabelId) {
          unitLabel = this.$store.getters['labels/label']('units', datapointAndTimeseries.unitLabelId)
        }
        const unit = unitLabel?.symbol

        return {
          color: this.$store.getters['data_points_view/getSeriesColorForDatapoint'](datapointAndTimeseries.hashID) as string,
          custom: { isBinary: datapointAndTimeseries.isBinary, unit },
          data: datapointAndTimeseries.timeseries,
          name: datapointAndTimeseries.dataPointID,
          visible: datapointAndTimeseries.visible,
        }
      })
    },
  },

  created () {
    if (this.liveViewActive) {
      this.updateLiveView()
    }
  },

  unmounted () {
    if (this.liveViewTimerHandle) {
      clearTimeout(this.liveViewTimerHandle)
    }
  },

  methods: {
    async fetchTimeseries (): Promise<void> {
      await this.$store.dispatch(
        'data_points_view/fetchTimeseries',
        this.$store.getters['data_points_view/getSelectedDatapointsHashIds'],
      )
    },

    onRouteUpdate (to: RouteLocationNormalized, from: RouteLocationNormalized) {
      if (
        to.params.project &&
        from.params.project &&
        parseInt(to.params.project as string) !== parseInt(from.params.project as string)
      ) {
        this.resetZoom()
        this.$store.commit('data_points_view/SET_SAMPLERATE', 'auto')
      }
    },

    resetZoom (): void {
      this.$store.commit('data_points_view/SET_ZOOM', null)
    },

    setDateRange (dates: string[]) {
      this.resetZoom()
      this.$store.dispatch('data_points_view/setDateRange', dates)
      this.fetchTimeseries()
    },

    async toggleLiveView (): Promise<void> {
      if (this.liveViewActive) {
        if (this.liveViewTimerHandle !== null) {
          clearTimeout(this.liveViewTimerHandle)
          this.liveViewTimerHandle = null
        }
        this.$store.dispatch('data_points_view/resetLiveViewRange')
        this.$store.commit('data_points_view/SET_LIVE_VIEW_STATUS', false)
        await this.fetchTimeseries()
      } else {
        this.$store.commit('data_points_view/SET_LIVE_VIEW_STATUS', true)
        this.updateLiveView()
      }
    },

    updateLiveView (): void {
      this.$store.dispatch('data_points_view/updateLiveViewRange')
      this.fetchTimeseries()
      this.liveViewTimerHandle = setTimeout(this.updateLiveView, 5000)
    },

    updateSamplerate (value: Samplerate): void {
      this.$store.commit('data_points_view/SET_SAMPLERATE', value)
      this.fetchTimeseries()
    },

    zoomChart (zoomParams?: ZoomParameters) {
      if (!this.ignoreZoomEvents) {
        if (zoomParams) {
          const zoom = {
            end: new Date(zoomParams.end),
            start: new Date(zoomParams.start),
          }
          if (!this.zoom || zoom.start !== this.zoom.start || zoom.end !== this.zoom.end) {
            this.$store.commit('data_points_view/SET_ZOOM', zoom)
            this.fetchTimeseries()
          }
        } else {
          this.resetZoom()
          this.fetchTimeseries()
        }
      }
    },
  },
})
</script>

<style lang="sass" scoped>
  .graph-settings__wrapper
    display: flex
    justify-content: space-between
    align-items: center
</style>
