import { Project, ProjectWithContext } from '@aedifion.io/aedifion-api'
import { ProjectCardData, ProjectLinkData, ProjectsState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { textForLocale } from '@/utils/helpers/locale'

function convertProjectLinksToProjectLinkData (project?: Project): ProjectLinkData[] {
  const result: ProjectLinkData[] = []
  for (const link of project?.links ?? []) {
    const titleForRouteName = link.titleEN.toLowerCase().replaceAll(' ', '_')
    const routeName = `${project?.id}_${titleForRouteName}`
    let path = `/${titleForRouteName}/${project?.id}`
    if (link.parent) {
      path = `/${link.parent}` + path
    }
    result.push({
      path,
      project: project!.id as number,
      routeName,
      source: link,
      target: textForLocale(link.urlDE, link.urlEN),
      title: textForLocale(link.titleDE, link.titleEN),
    })
  }
  return result
}

const sortProjects = (projectA: Project|ProjectCardData, projectB: Project|ProjectCardData): number => {
  return projectA.name.toLowerCase().localeCompare(projectB.name.toLowerCase())
}

export default {
  currentProject: (state: ProjectsState): Project|null => {
    let result: Project|null = null
    if (state.currentProjectId) {
      result = state.projects.find((project: ProjectWithContext) => {
        return project.project!.id === state.currentProjectId
      })?.project ?? null
    }
    return result
  },

  currentProjectId: (state: ProjectsState): number|null => state.currentProjectId,

  getCondensedProjects: (state: ProjectsState): Project[] => {
    const projects = state.projects.map((project: ProjectWithContext) => {
      return {
        avatar_url: project.project!.avatar_url,
        company_id: project.project!.company_id,
        description: project.project!.description,
        id: project.project!.id,
        latitude: project.project!.latitude,
        longitude: project.project!.longitude,
        name: project.project!.name,
      }
    })

    return projects.sort(sortProjects)
  },

  getProjectsIds: (_state: ProjectsState, getters): number[] => {
    return getters.projects.map((project: ProjectWithContext) => project.project!.id!)
  },

  isLoading: (state: ProjectsState): boolean => state.loading,

  linksForAllProjects: (state: ProjectsState): ProjectLinkData[] => {
    const result: ProjectLinkData[] = []
    for (const projectWithContext of state.projects) {
      result.push(...convertProjectLinksToProjectLinkData(projectWithContext.project))
    }
    return result
  },

  linksForCurrentProject: (state: ProjectsState): ProjectLinkData[] => {
    if (state.currentProjectId) {
      const currentProjectWithContext = state.projects.find((project: ProjectWithContext) => {
        return project.project!.id === state.currentProjectId
      })
      return convertProjectLinksToProjectLinkData(currentProjectWithContext?.project)
    }
    return []
  },

  project: (state: ProjectsState) => (projectId: number): ProjectWithContext|null => {
    return state.projects.find((project: ProjectWithContext) => project.project?.id === projectId) ?? null
  },

  projectCardsData: (state: ProjectsState): ProjectCardData[] => {
    const projects: ProjectCardData[] = state.projects.map((project: ProjectWithContext) => {
      return {
        avatar: project.project!.avatar_url,
        description: project.project!.description,
        id: project.project!.id!,
        name: project.project!.name,
      }
    })
    return projects.sort(sortProjects)
  },

  projectIds: (state: ProjectsState): number[] => state.projects.map(
    (project: ProjectWithContext) => project.project?.id,
  ).filter(
    (projectId: number|undefined) => typeof projectId === 'number',
  ) as number[],

  projects: (state: ProjectsState): ProjectWithContext[] => state.projects,

  projectsWithLocation: (state: ProjectsState): ProjectWithContext[] => {
    return state.projects.filter((project: ProjectWithContext) => project.project?.latitude && project.project?.longitude)
  },
} as GetterTree<ProjectsState, RootState>
