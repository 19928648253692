<script setup lang="ts">
import { ComponentInProjectListItemData, FetchComponentsInProjectPayload } from '@/vuex/components_in_project/types'
import { computed, ref } from 'vue'
import AsideList from '@/components/AsideList/AsideList.vue'
// import ComponentsInProjectFilter from './ComponentsInProjectFilter.vue'
import ComponentsInProjectListItem from './ComponentInProjectListItem.vue'
import debounce from 'lodash.debounce'
import { docsLink } from '@/utils/helpers/locale'
import NewComponentInProjectDialog from '@/views/Optimization/Components/NewComponentInProjectDialog/index.vue'
import { reportError } from '@/utils/helpers/errors'
import SearchInput from '@/components/SearchInput.vue'
import { useI18n } from 'vue-i18n'
import useIsReadOnly from '@/composables/useIsReadOnly'
import { useRoute } from 'vue-router'
import { useStore } from '@/vuex'

// --- definition ---

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const { isReadOnly } = useIsReadOnly()

const manualLink = ref<string|null>(docsLink('mapping_manual'))
const showNewComponentDialog = ref(false)

const componentsInProject = computed<ComponentInProjectListItemData[]>(() => store.getters['components_in_project/componentsInProjectListItems'])
const isLoadingComponentsInProject = computed<boolean>(() => store.getters['components_in_project/isLoadingComponentsInProject'])
const page = computed<number>(() => store.getters['components_in_project/currentPage'])
const totalPages = computed<number|null>(() => store.getters['components_in_project/totalPages'])
const search = computed<string>(() => store.getters['optimization/search'])

const applySearchDelayed = debounce(function (search: string) {
  store.commit('optimization/SET_SEARCH', search)
  fetchComponents(1)
}, 500)

async function fetchComponents (page: number) {
  const filterValue: string = route.query.filter as string ?? ''
  const payload: FetchComponentsInProjectPayload = {
    filter: filterValue ? `alphanumeric_id=${filterValue}` : undefined,
    page,
    search: search.value,
  }
  try {
    await store.dispatch('components_in_project/fetchComponentsInProjectWithResults', payload)
  } catch (error) {
    reportError(error)
  }
}

function onPageChange (newPage: number) {
  fetchComponents(newPage)
}
</script>

<template>
  <AsideList
    :items="componentsInProject"
    :loading="isLoadingComponentsInProject"
    :page="page"
    :pages-count="totalPages"
    v-bind="$attrs"
    @asidelist:page-change="onPageChange"
  >
    <template #content-header>
      <div class="pa-4 d-flex gap">
        <v-menu
          location="bottom"
          close-delay="200"
          :offset="[10, 0]"
          open-delay="500"
          open-on-hover
        >
          <template #activator="{ props }">
            <v-btn
              v-if="!isReadOnly"
              class="text-primary-darken2 new-component-button"
              color="primary-lighten3"
              data-cy="createNewComponentButton"
              v-bind="props"
              @click="showNewComponentDialog = true"
            >
              {{ t('create_component.button') }}
              <v-icon
                color="primary-darken2"
                end
              >
                fa:far fa-plus-circle
              </v-icon>
            </v-btn>
          </template>

          <v-card max-width="300">
            <v-card-title>
              {{ t('create_component.manual_title') }}
            </v-card-title>
            <v-card-text>
              {{ t('create_component.manual_text') }}
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="pr-1"
                color="primary"
                :href="manualLink"
                size="small"
                target="_blank"
                variant="text"
              >
                {{ t('create_component.manual_button') }}
                <v-icon
                  end
                  size="x-small"
                >
                  fa:far fa-external-link
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <NewComponentInProjectDialog
          :show-new-component-dialog="showNewComponentDialog"
          @newcomponentinprojectdialog:close="showNewComponentDialog = false"
        />
        <SearchInput
          :placeholder="t('search')"
          :model-value="search"
          @update:model-value="applySearchDelayed"
        />
        <!-- <ComponentsInProjectFilter /> -->
      </div>
    </template>
    <template #list-item="{ itemData }">
      <ComponentsInProjectListItem
        :item-data="itemData"
        :read-only="isReadOnly"
        v-bind="$attrs"
      />
    </template>
  </AsideList>
</template>

<style lang="sass" scoped>
  .new-component-button
    min-height: 40px
    padding: 0 12px

  :deep(.v-list-item)
    padding-left: 0 !important
    padding-right: 0 !important
    min-height: 56px
</style>

<i18n locale="de">
  {
    "create_component": {
      "button": "Neu",
      "manual_button": "Anleitung",
      "manual_title": "Neue Komponente anlegen",
      "manual_text": "Erstelle eine neue Instanz einer Komponente und verlinke Datenpunkte auf ihre Pins."
    },
    "search": "Suche"
  }
  </i18n>
  <i18n locale="en">
  {
    "create_component": {
      "button": "New",
      "manual_button": "Learn more",
      "manual_title": "Create new component",
      "manual_text": "Create a new instance of a component type and map datapoints to its pins."
    },
    "search": "Search"
  }
  </i18n>
