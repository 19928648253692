import Highcharts from 'highcharts'
import { TimeseriesShort } from '@/vuex/data_points_view/types'

export function mapLineChartSeriesToXRange (series: Highcharts.SeriesLineOptions, index = 0): Highcharts.SeriesXrangeOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const originalData = (series as any).data as TimeseriesShort<number>
  const data = []
  let startSignal: number|null = null
  let valuesInCurrentSignal = new Set<number>()

  for (const observation of originalData) {
    const observationValue = observation[1] as number
    if (observationValue !== null && observationValue > 0.5) {
      valuesInCurrentSignal.add(observationValue)
      if (startSignal === null) {
        startSignal = observation[0]
      }
    }

    if (startSignal !== null && observationValue !== null && observationValue <= 0.5) {
      data.push({
        color: series.color,
        custom: {
          values: Array.from(valuesInCurrentSignal),
        },
        x: startSignal,
        x2: observation[0],
        y: index,
      })
      startSignal = null
      valuesInCurrentSignal = new Set()
    }
  }

  if (startSignal !== null) {
    data.push({
      color: series.color,
      custom: {
        values: Array.from(valuesInCurrentSignal),
      },
      x: startSignal,
      x2: originalData[originalData.length - 1][0],
      y: index,
    })
  }

  const result = { ...series, data }

  return result as Extract<typeof result, Highcharts.SeriesXrangeOptions>
}
