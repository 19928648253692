<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits<{
  (event: 'add-comment', comment: string): void
  (event: 'cancel-add-comment'): void
}>()

const { t } = useI18n()

const newComment = ref('')

const isCommentValid = computed(() => {
  return newComment.value.length > 0
})

const onAddComment = () => {
  emit('add-comment', newComment.value)
}
</script>

<template>
  <v-form
    data-testid="add-comment-form"
    @submit.prevent="onAddComment"
  >
    <v-textarea
      v-model="newComment"
      class="mb-1"
      no-resize
      rows="4"
      hide-details
      data-testid="add-comment-textarea"
      :label="t('new_comment')"
    />
    <v-card-actions class="pa-0">
      <v-btn
        variant="flat"
        class="flex-grow-1 text-primary d-flex align-center tw-content-center"
        color="primary-lighten3"
        data-testid="add-comment-cancel-button"
        height="40px"
        @click="emit('cancel-add-comment')"
      >
        <span class="tw-leading-5">{{ t('cancel_btn_text') }}</span>
        <v-icon
          end
          size="14"
        >
          fa:far fa-xmark
        </v-icon>
      </v-btn>
      <v-btn
        variant="flat"
        color="primary-darken2"
        class="flex-grow-1 text-white"
        data-testid="add-comment-save-button"
        height="40px"
        :disabled="!isCommentValid"
        type="submit"
      >
        <span class="tw-leading-5">{{ t('save_btn_text') }}</span>
        <v-icon
          end
          size="14"
        >
          fa:far fa-check
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<i18n lang="json" locale="de">
  {
    "new_comment": "Neuer Kommentar",
    "save_btn_text": "Speichern",
    "cancel_btn_text": "Abbrechen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "new_comment": "New Comment",
    "save_btn_text": "Save",
    "cancel_btn_text": "Cancel"
  }
</i18n>
